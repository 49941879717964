import { memo, useEffect, useState } from "react";
import React from "react";
import NavBar from "../../Header/NavBar";
import { Col, Row, Space, Table } from "antd";
import Sidemenu from "../../Sidemenu/Sidemenu";
import Recentprompttable from "./Recentprompttable"
import { Icon } from "@iconify/react";
const RecentPromptsDesktop = () => {
    return (
        <>
            <NavBar />
            <Row>
                <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={2}
                    xl={2}
                    className="body-margin-top body-margin-bottom-mobile"
                >
                    <Sidemenu />
                </Col>
                <Col
                 xs={24}
                 sm={24}
                 md={24}
                 lg={22}
                 xl={22}>
                    <Recentprompttable/>
                
                </Col>

            </Row>

        </>
    )
}
export default RecentPromptsDesktop;