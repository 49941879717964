import { useState, useEffect } from "react";
import { Col, Row } from "antd";
import "../CSS/dashboardnew.scss";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { getcreditavatar, getpromptdaywise } from "../../api/accountAction";

const Promptusage = () => {
  const { dashbaorddate, offerstatus } = useSelector((state) => state.chat);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [labelchart, setLabelChart] = useState([]);
  const [numericvalue, setNumericValue] = useState([]);
  const [creditwise, setCreditWise] = useState([]);
  const [labelday, setlabelday] = useState([]);
  const [numbericday, setNumbericday] = useState([]);
  const [threemonthdata, setThreeMonthData] = useState({
    series: [],
    option: [],
  });

  const handleIconClick = () => {
    setIsPopoverVisible(!isPopoverVisible);
  };
  const dispatch = useDispatch();
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );

  const currentThreeMonthDate = new Date(dashbaorddate);
  const year = currentThreeMonthDate.getFullYear();
  const month = (currentThreeMonthDate.getMonth() + 1)
    .toString()
    .padStart(2, "0");

  useEffect(() => {
    const daywise = {
      event_type: "daywise_question_token",
      company: companyName,
      user_id: userName,
      date_time: dashbaorddate,
      user_type: offerstatus,
    };

    dispatch(getpromptdaywise(daywise))
      .unwrap()
      .then(({ data }) => {
        const creditdaywise = JSON.parse(data.aivolvex_control_plane);
        setCreditWise(creditdaywise);
        if (creditdaywise) {
          const dates = creditdaywise.map((item) => item.date_time);
          const allDates = Array.from({ length: 31 }, (_, i) =>
            String(i + 1).padStart(2, "0")
          );
          const tokensUsed = Array(31).fill(0);
          allDates.forEach((day, index) => {
            const indexInData = dates.findIndex((date) =>
              date.endsWith(`-${day}`)
            );
            if (indexInData !== -1) {
              tokensUsed[index] = parseInt(
                creditdaywise[indexInData].question_count,
                10
              );
            }
          });
          setlabelday(allDates);
          setNumbericday(tokensUsed);
        } else {
          console.log("No data found.");
        }
      })

      .catch((err) => {
        console.log(err);
      });

    const input = {
      event_type: "avatar_wise_question_count",
      company: companyName,
      user_id: userName,
      date_time: dashbaorddate,
      user_type: offerstatus,
    };

    dispatch(getcreditavatar(input))
      .unwrap()
      .then(({ data }) => {
        const avatarprompt = JSON.parse(data.aivolvex_control_plane);
        var avatar_list = avatarprompt.map((item) => item.avatar_name);
        avatar_list = new Set([...avatar_list]);
        avatar_list = [...avatar_list];
        const monthNames = [
          "Jan",
          "Feb",
          "March",
          "April",
          "May",
          "June",
          "July",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        //start :get last 3 month
        const lastThreeMonthsDate = new Date(dashbaorddate);
        lastThreeMonthsDate.setMonth(currentThreeMonthDate.getMonth() - 2);
        var month_list = [];
        var chart_records = [];
        var chart_avatar_records = [];
        for (let i = 0; i < 3; i++) {
          let date = new Date(
            lastThreeMonthsDate.getFullYear(),
            lastThreeMonthsDate.getMonth() + i,
            1
          );
          const checkdate = `${date.getFullYear()}-${date.getMonth() + 1}-01`;
          const monthIndex = new Date(checkdate).getMonth();
          const monthName = monthNames[monthIndex];
          avatar_list.map((avatarname) => {
            const monthtokenrecords = avatarprompt.filter((datas) => {
              const inputDate = datas.month;
              const inputDatemonth = inputDate.split("-")[1];
              return (
                datas.avatar_name?.toLowerCase() ===
                  avatarname?.toLowerCase() && inputDatemonth == monthIndex + 1
              );
            });
            if (monthtokenrecords.length > 0) {
              chart_records.push({
                month: checkdate,
                name: avatarname,
                data: monthtokenrecords[0].question_count,
              });
            } else {
              chart_records.push({
                month: checkdate,
                name: avatarname,
                data: 0,
              });
            }
          });
          month_list.push(monthName);
        }
        const chart_avatar_values = [];
        avatar_list.map((avatarname) => {
          const last3month = [];
          for (let i = 0; i < 3; i++) {
            let date = new Date(
              lastThreeMonthsDate.getFullYear(),
              lastThreeMonthsDate.getMonth() + i,
              1
            );
            const checkdate = `${date.getFullYear()}-${date.getMonth() + 1}-01`;
            const monthIndex = new Date(checkdate).getMonth();

            const monthtokenrecords = chart_records.filter((datas) => {
              const inputDate = datas.month; // Example date in "YYYY-MM" format
              const inputDatemonth = inputDate.split("-")[1];
              return (
                datas.name?.toLowerCase() === avatarname?.toLowerCase() &&
                inputDatemonth == monthIndex + 1
              );
              // return datas.all_cloud?.toLowerCase() === envdata?.toLowerCase();
            });
            if (monthtokenrecords.length > 0) {
              last3month.push(monthtokenrecords[0].data);
            } else {
              last3month.push(0);
            }
          }
          chart_avatar_values.push({
            name: avatarname,
            data: last3month,
          });
        });
        setThreeMonthData({
          series: chart_avatar_values,
          option: month_list,
        });

        const currentDatevalue = new Date(dashbaorddate);
        const currentYear = currentDatevalue.getFullYear();
        const currentMonth = (currentDatevalue.getMonth() + 1)
          .toString()
          .padStart(2, "0");
        const currentMonthData = avatarprompt.filter((item) => {
          const [year, month] = item.month.split("-");
          return year === String(currentYear) && month === currentMonth;
        });

        if (currentMonthData.length > 0) {
          const label_chart = currentMonthData.map((item) => item.avatar_name);
          setLabelChart(label_chart);
          const month = currentMonthData.map((item) => item.month);
          const chartdata = currentMonthData.map((item) => item.question_count);
          const numericValues_db = chartdata.map((value) =>
            parseInt(value, 10)
          );
          setNumericValue(numericValues_db);
        } else {
          console.log("No avatars found.");
        }
      })

      .catch((err) => {
        console.log(err);
      });
  }, [dashbaorddate, offerstatus]);
  const dashboardDate = new Date(dashbaorddate);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName = monthNames[dashboardDate.getMonth()];

  const promptseries = threemonthdata.series;

  const promptoptions = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      stackType: "100%",
    },
    grid: {
      show: true,
      borderColor: "#42445f",
    },
    plotOptions: {
      bar: {
        columnWidth: "40%",
      },
    },
    legend: {
      show: false,
    },
    title: {
      text: "Last Three Months Prompts",
      align: "left",
      style: {
        color: "#FFFFFF",
        fontWeight: "lighter",
      },
    },
    xaxis: {
      categories: threemonthdata.option,
      labels: {
        style: {
          colors: "#fff",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#42445f",
        },
      },
      title: {
        text: "Prompts",
        style: {
          color: "#fff",
        },
      },
    },
    fill: {
      colors: [
        "#B2A4FF",
        "#FFB4B4",
        "#65CCD2",
        "#FDF7C3",
        "#FFB4D8",
        "#D0FFB4",
      ],
    },
    options: {
      legend: {
        position: "bottom",
        markers: {
          size: 12,
          strokeWidth: 0,
        },
        labels: {
          colors: "#FFFFFF",
        },
      },
    },

    dataLabels: {
      enabled: false,
    },
  };

  const series_db = numericvalue;
  const chartData_db = {
    labels: labelchart,
    series: series_db,
  };

  const options_db = {
    chart: {
      type: "pie",
    },
    labels: labelchart,
    dataLabels: {
      formatter: function (val) {
        return val.toFixed(0) + "%";
      },
    },
    legend: {
      position: "right",
      horizontalAlign: "center",
      show: true,
      position: "right",
      labels: {
        colors: "#FFFFFF",
      },

      markers: {
        width: 13,
        height: 13,
        radius: 4,
        strokeWidth: 0,
      },
    },
    tooltip: {
      enabled: true,
      theme: "light",
      x: {
        show: false,
      },
      y: {
        formatter: function (val) {
          const billion = 1000000000;
          const million = 1000000;
          const thousand = 1000;

          if (val >= billion) {
            return (val / billion).toFixed(1) + "B";
          } else if (val >= million) {
            return (val / million).toFixed(1) + "M";
          } else if (val >= thousand) {
            return (val / thousand).toFixed(1) + "K";
          } else {
            return val.toString();
          }
          return val;
        },
      },
    },
    colors: ["#FFD6A5", "#A5EFFF", "#CBFFA9", "#FF9B9B", "#FFFEC4", "#8093F1"],
    stroke: {
      show: false,
    },
    title: {
      // text: "Current Month Prompts",
      text: `${monthName} Month Prompts`,
      align: "left",
      style: {
        color: "#FFFFFF",
        fontWeight: "lighter",
      },
    },
  };

  const options = {
    chart: {
      type: "pie",
    },
    legend: {
      position: "right",
      horizontalAlign: "center",
      labels: {
        colors: "#FFFFFF",
      },
      markers: {
        width: 13,
        height: 13,
        radius: 4,
        strokeWidth: 0,
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: false, // If you don't want to show the x-value
      },
      y: {
        formatter: function (val) {
          const billion = 1000000000;
          const million = 1000000;
          const thousand = 1000;

          if (val >= billion) {
            return (val / billion).toFixed(1) + "B";
          } else if (val >= million) {
            return (val / million).toFixed(1) + "M";
          } else if (val >= thousand) {
            return (val / thousand).toFixed(1) + "K";
          } else {
            return val.toString();
          }
          return val;
        },
      },
    },
    colors: ["#F6AA54", "#9FDEF1", "#A7EE7C", "#6197E2", "#FFF", "#2A5D78"],
    stroke: {
      show: false,
    },
    title: {
      text: "Current Month Prompt",
      align: "left",
      style: {
        color: "#FFFFFF",
        fontWeight: "lighter",
      },
    },
  };

  const lineseries = [
    {
      name: "Credit",
      data: numbericday,
      color: "#72DDF7",
    },
  ];

  const lineoptions = {
    chart: {
      height: 350,
      type: "area",
      zoom: {
        enabled: false,
      },
    },
    grid: {
      show: true,
      borderColor: "#42445f",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 0,
    },
    color: ["#fff"],
    title: {
      text: "Prompts By Day",
      align: "left",
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: "Poppins",
        color: "#fff",
      },
    },
    xaxis: {
      categories: labelday,
      labels: {
        rotate: -45,
        rotateAlways: true,
        style: {
          fontSize: "10px",
          colors: "#ffffff",
        },
        formatter: (num) => {
          const monthNamesShort = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];

          const date = new Date(dashbaorddate);
          const day = date.getDate();
          const month = monthNamesShort[date.getMonth()];
          const current_month_text = `${num}/${month}`;
          return current_month_text;
        },
      },
      title: {
        text: "Prompts Count",
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          fontFamily: "Poppins",
          color: "#fff",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#42445f",
        },
        formatter: (value) => {
          const num = (Math.round(value * 100) / 100).toFixed(2);
          return Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
            : Math.sign(num) * Math.abs(num);
        },
      },
    },
  };

  return (
    <>
      <Row className="mt-4">
        <Col span={24} className="d-flex justify-content-center">
          <h4 className="dashboard-credit aivolvex-font">Prompt Usage Trend</h4>
        </Col>
      </Row>
      <Row
        className="mt-3 d-flex justify-content-between"
        gutter={[16, 16, 16, 16]}
      >
        <Col span={12}>
          <div className="aivolvex-dashboard-outline full-width">
            <div className="Dashboard_graph_box Dashboard_head_box_shadow">
              <div className="Dashboard_head_content">
                <div id="chart">
                  <ReactApexChart
                    options={options_db}
                    series={chartData_db.series}
                    type="pie"
                    height={234}
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className="aivolvex-dashboard-outline full-width">
            <div className="Dashboard_graph_box Dashboard_head_box_shadow">
              <div className="Dashboard_head_content">
                <div id="chart">
                  <>
                    <ReactApexChart
                      options={promptoptions}
                      series={promptseries}
                      type="bar"
                      height={220}
                      toolbar={false}
                    />
                  </>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row
        className="mt-3 d-flex justify-content-between"
        gutter={[16, 16, 16, 16]}
      >
        <div className="aivolvex-dashboard-outline full-width">
          <div className="Dashboard_graph_box Dashboard_head_box_shadow">
            <div className="Dashboard_head_content">
              <div id="chart">
                <ReactApexChart
                  options={lineoptions}
                  series={lineseries}
                  type="area"
                  height={234}
                />
              </div>
            </div>
          </div>
        </div>
      </Row>
    </>
  );
};

export default Promptusage;
