import { memo } from "react";
import "./CSS/conversation.css";
import DesktopConversationBody from "./Desktop/DesktopConversationBody";

const ConversationBody = () => {
  return (
    <>
      <DesktopConversationBody />
    </>
  );
};

export default memo(ConversationBody);
