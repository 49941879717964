import { configureStore } from "@reduxjs/toolkit";
import chatReducer from "../Slice/reducer/chatReducer";
import lastWordReducer from "../Slice/slice"; 
import sessionReducer from "../Slice/slice";

const store = configureStore({
  reducer: {
    chat: chatReducer,
    lastword: lastWordReducer,
    session:sessionReducer

  },
});
export default store;
