import { memo, useState } from "react";
import NavBar from "../Header/NavBar";
import Sidemenu from "../Sidemenu/Sidemenu";
import { Col, Row, Tabs } from "antd";
import FavoritesPrompts from "./FavoritesPrompts";
import FavoritesAvatar from "./FavoritesAvatar";
// import "./library.css";

const Favorites = () => {
  const [basicActive, setBasicActive] = useState("1");
  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };
  const items = [
    {
      key: 1,
      label: "Avatar",
      children: <FavoritesAvatar />,
      className: "plat-dashboard-link",
      onClick: () => handleBasicClick(1),
      active: basicActive === 1,
    },
    {
      key: 2,
      label: "Prompts",
      children: <FavoritesPrompts />,
      className: "plat-dashboard-link",
      onClick: () => handleBasicClick(2),
      active: basicActive === 2,
    },
  ];
  return (
    <>
      <NavBar />
      <div className="body-bg">
        <Row>
          <Col span={5} className="window-margin-right  body-margin-top">
            <Sidemenu />
          </Col>
          <Col span={18} className="body-margin-top">
            <Row className="mt-4">
              <Col span={24} className="d-flex justify-content-between">
                <h4 className="white-color">Favorites</h4>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Tabs
                  className="cams-tabs aivolvex-font"
                  items={items}
                  onChange={handleBasicClick}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default memo(Favorites);
