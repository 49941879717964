import { memo, useEffect, useState } from "react";
import { Col, Collapse, Row, Button } from "antd";
import { Icon } from "@iconify/react";
import AvatarList from "./AvatarList";
import aivolvex from "../../../assets/ui/X Icon SVG.svg";
import { getAvatarList } from "../Auth/ConversationAuth";
import { useDispatch, useSelector } from "react-redux";
import {
  listAvatar,
  getTokenRemaining,
  listPinned,
} from "../../api/accountAction";
import { useNavigate } from "react-router-dom";
import {
  setChatSessionID,
  setTempChatSessionID,
  removeHistoryChatMessage,
} from "../../Slice/reducer/chatReducer";
import { useParams } from "react-router-dom";

const PodList = () => {
  const dispatch = useDispatch();
  const navigateFn = useNavigate();
  const { id, conversationId } = useParams();

  const [preloader, setPreloader] = useState(false);
  const [podlist, setPodList] = useState([]);
  const [records, setRecords] = useState([]);
  const [notpinnedavatar, setNotPinnedAvatar] = useState([]);
  const [token, setToken] = useState(0);
  const { pinnedstatus, creditstokenstatus } = useSelector(
    (state) => state.chat
  );

  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );
  const [avatarlist, setAvatarList] = useState([]);

  useEffect(() => {
    if (notpinnedavatar.length > 0) {
      const input = {
        company: companyName,
        user_id: userName,
      };
      dispatch(listPinned(input))
        .unwrap()
        .then(({ data }) => {
          setPreloader(false);
          const all_pod = [
            {
              key: notpinnedavatar.length,
              label: "Pinned Agent",
              children: (
                <AvatarList
                  avatarlist={JSON.parse(data.aivolvex_control_plane)}
                />
              ),
            },
          ].concat(notpinnedavatar);
          setPodList(all_pod);
        })
        .catch((err) => {
          console.log(err);
          // message.error(err);
        });
    }
  }, [notpinnedavatar, pinnedstatus]);
  useEffect(() => {
    const input = {
      company_name: companyName,
      user_id: userName,
    };
    dispatch(listAvatar(input))
      .unwrap()
      .then(({ data }) => {
        // console.log(data);
        if (JSON.parse(data.aivolvex_control_plane).length > 0) {
          var all_pod_list = JSON.parse(data.aivolvex_control_plane).map(
            (v) => v.pod
          );
          const pod = new Set([...all_pod_list]);
          setRecords(JSON.parse(data.aivolvex_control_plane));
          const item_list = [...pod].map((pod_name, index) => {
            return {
              key: index,
              label: pod_name,
              children: (
                <AvatarList
                  avatarlist={JSON.parse(data.aivolvex_control_plane).filter(
                    (v, index) => v.pod === pod_name
                  )}
                />
              ),
            };
          });
          setNotPinnedAvatar(item_list);
        } else {
          setPreloader(false);
          setPodList([]);
          setRecords([]);
        }
      })
      .catch((err) => {
        console.log(err);
        // message.error(err);
      });
  }, []);

  useEffect(() => {
    const input_value = {
      company: companyName,
      avatar_id: id,
    };
    dispatch(getTokenRemaining(input_value))
      .unwrap()
      .then(({ data }) => {
        const records = JSON.parse(data.aivolvex_control_plane);
        setToken(records.remaining_credit);
      })
      .catch((err) => {
        console.log(err);
        // message.error(err);
      });
  }, [id, creditstokenstatus]);

  const items = podlist;
  return (
    <>
      <div>
        <Row>
          <Col span={24}>
            <Button
              type="primary"
              icon={<Icon icon="fluent:add-20-filled" />}
              size="large"
              className="volvex-btn aivolvex-font"
              onClick={() => {
                dispatch(setChatSessionID(""));
                dispatch(setTempChatSessionID(""));
                dispatch(removeHistoryChatMessage());
                navigateFn("/conversation");
              }}
            >
              New Chat
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Collapse
              className="volvex-avatar-list-collapse aivolvex-font"
              // defaultActiveKey={["0"]}
              ghost
              items={items}
            />
          </Col>
        </Row>
        {id && (
          <Row>
            <Col span={24}>
              <div className="volvex-avatar-list-footer">
                <div className="d-flex justify-content-between">
                  <div className="aivolvex-font">AIVolveX Credits </div>
                  <div>
                    <img
                      src={aivolvex}
                      alt="logo"
                      className="token-footer-logo aivolvex-font"
                    />{" "}
                    {token}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default memo(PodList);
