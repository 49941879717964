import React from "react";
// import signInCarousel3Image from "../../assets/banner/Banner_3.jpg";
import signInCarousel3Image from "../../assets/banner/Banner-3.jpg";
function SignInCarousel3() {
  return (
    <div className="d-flex justify-content-center new-banner-image-back">
      <div className="text-black">
        <div className="text-center carousel-header carousel-text">
          {/* <img src={signInCarousel3Image} className="signInCarousel3Image" /> */}
          <img src={signInCarousel3Image} className="signInCarousel1Image" />

          {/* <p className="mt-2 siginbanner-heading">
            Join us on this exhilarating journey as we evolve together,
            unlocking the limitless potential of Al and creating a future that
            is truly remarkable. Welcome to the Alvolution with AlVolveX.Ai.{" "}
          </p> */}
        </div>
        {/* <div className="text-center mt-2 carousel-content">
          <p>Introducing the new generation of mentors - Superlancers, who</p>
          <p>expertly curate and manage a top-tier network of independent</p>
          <p>talents.</p>
          <p>
            {" "}
            take the assessment to create a difference in the freelancing world!
          </p>
        </div> */}
      </div>
    </div>
  );
}

export default SignInCarousel3;
