import React, { useEffect, useState } from "react";
import { Col, Row, Avatar, Card, Modal, message } from "antd";
import areachart from "../../../assets/chart/area-chart.png";
import barchart from "../../../assets/chart/bar.png";
import donutchart from "../../../assets/chart/donut.png";
import hbarchart from "../../../assets/chart/hori_bar.png";
import piechart from "../../../assets/chart/pie_chart.png";
import scatterchart from "../../../assets/chart/scatterchart.png";
import { useDispatch } from "react-redux";
import { generateTableChart } from "../../api/accountAction";
import preloader from "../../../assets/loader/pre_loader.gif";

const Chartgraph = (props) => {
    const dispatch = useDispatch();
    const [preloaderstatus, setPreloaderStatus] = useState(false);

    const getConversationGraph = (type) => {
        setPreloaderStatus(true);
        // console.log(type);
        // console.log(props)
        const input = {
            html_string: props.graphrecord,
            chart_types: type,
        };
        // console.log(input);
        dispatch(generateTableChart(input))
            .unwrap()
            .then(({ data }) => {
                setPreloaderStatus(false);
                // console.log(data)
                if (JSON.parse(data.aivolvex_control_plane).message) {
                    message.error(
                        "Chart generation not possible. This content does not contain a table."
                    );
                } else {
                    const resopnce = JSON.parse(data.aivolvex_control_plane).base64;
                    downloadBase64Image(resopnce, "image");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    function downloadBase64Image(base64Data, filename) {
        // Convert base64 to binary data
        const byteCharacters = atob(base64Data);
        const byteArrays = [];

        for (let i = 0; i < byteCharacters.length; i++) {
            byteArrays.push(byteCharacters.charCodeAt(i));
        }

        const byteArray = new Uint8Array(byteArrays);

        // Create a Blob object
        const blob = new Blob([byteArray], { type: "image/jpeg" }); // Change the type to match your image format

        // Create a download link
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;

        // Trigger a click event on the link to start the download
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
    }

    return (
        <>
            <Row gutter={[24, 24]}>
                {preloaderstatus ? (
                    <>
                        <Col span={24}>
                            <div className="aivolvex-sub-loader h-18">
                                <img src={preloader} width="20%" />
                            </div>
                        </Col>
                    </>
                ) : (
                    <>
                        <Col span={8}>
                            <Card
                                className="chart-graph"
                                onClick={() => getConversationGraph("area")}
                            >
                                {props.html_string}
                                <Row>
                                    <img src={areachart} className="chart-img" />
                                </Row>
                                <Row className="chart-graph-text"> Area</Row>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card
                                className="chart-graph"
                                onClick={() => getConversationGraph("bar")}
                            >
                                <img src={barchart} className="chart-img" />
                                <Row className="chart-graph-text"> Bar</Row>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card
                                className="chart-graph"
                                onClick={() => getConversationGraph("scatter")}
                            >
                                <img src={donutchart} className="chart-img" />
                                <Row className="chart-graph-text">Scatter</Row>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card
                                className="chart-graph"
                                onClick={() => getConversationGraph("horizontal_bar")}
                            >
                                <img src={hbarchart} className="chart-img" />
                                <Row className="chart-graph-text">Horizontal Bar</Row>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card
                                className="chart-graph"
                                onClick={() => getConversationGraph("pie")}
                            >
                                <img src={piechart} className="chart-img" />
                                <Row className="chart-graph-text">Pie</Row>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card
                                className="chart-graph"
                                onClick={() => getConversationGraph("histogram")}
                            >
                                <img src={scatterchart} className="chart-img" />
                                <Row className="chart-graph-text">Histogram</Row>
                            </Card>
                        </Col>
                    </>
                )}
            </Row>
        </>
    );
};
export default Chartgraph;
