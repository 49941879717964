import React, { useState } from "react";
import mobilebanner2 from "../../assets/mobile-banner-2.png";
import mobileback from "../../assets/mobilebackbanner.png";
import mobilelogo from "../../assets/mobilelogo.svg";
import { Icon } from "@iconify/react";
import { useDispatch } from "react-redux";
import {
  Button,
  Row,
  Col,
  Avatar,
  Form,
  Input,
  message,
  Modal,
  Alert,
} from "antd";
import "./mobilesignin.css";
import { useNavigate } from "react-router-dom";

const MobileSignin = ({
  emailidVerification,
  onFinishAzureAd,
  email,
  setEmail,
  password,
  setPassword,
  isSubmitting,
  setIsSubmitting,
  azureerrorstatus,
  setAzureErrorStatus,
  onFinishForgotPasswordSubmit,
  validatePassword,
  onFinishForgotPasswordOTP,
  forgotemail,
  setForgotEmail,
  setOpenForgotPassword,
  openforgotpassword,
  setShowMobileSignin,
}) => {
  const [form] = Form.useForm();
  const [buttonloader, setButtonLoader] = useState(false);
  const navigate = useNavigate();
  const [formforgotstatus, setFormForgotStatus] = useState(true);
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleSubmit = (value) => {
    // console.log(value);
    setEmail(value.name);
    setPassword(value.password);
    // emailidVerification(value);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const hanldesignup = () => {
    navigate("./signup");
  };
  const handleback = () => {
    setShowMobileSignin(false);
  };
  return (
    <>
      <div className="mobile-banner banner-bg">
        <Row>
          <Col className="mobile-col">
            <img src={mobilebanner2} />
          </Col>
        </Row>
        <Row className="mobile-banner-logo mobile-view-avatar tab-avatar">
          <Col>
            <Avatar
              size={64}
              icon={<Icon icon="mdi:user" className="avatar-user" />}
            />
          </Col>
        </Row>

        <Row className="mobile-banner-logo">
          <Col>
            <span className="mobile-banner-text-normal">Sign in</span>
          </Col>
        </Row>
        <Row className="signin-form">
          <Col>
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
              <Row>
                <Col>
                  <Form.Item
                    label="Name or Email"
                    name="name"
                    rules={[{ required: true, message: "" }]}
                  >
                    <Input
                      placeholder="Enter your name or email"
                      className="signin-form-input"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: "" }]}
                  >
                    <Input
                      type="password"
                      placeholder="Enter your password"
                      className="signin-form-input"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                {/* <Col>
                                    <Form.Item label="Password" name="password" rules={[{ required: true, message: "" }]}>
                                        <Input.Password placeholder="Enter your password" className="signin-form-input" onChange={(e) => setPassword(e.target.value)} />
                                    </Form.Item>
                                </Col> */}
              </Row>
              <Row>
                <Col span={24}>
                  <div
                    className="forgot-password"
                    onClick={() => {
                      setOpenForgotPassword(true);
                      setFormForgotStatus(true);
                      const records = {
                        email: "",
                      };
                      form.setFieldsValue(records);
                    }}
                  >
                    Forgot Password?
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item>
                    <Button
                      className="signin-btn"
                      htmlType="submit"
                      loading={isSubmitting}
                      onClick={emailidVerification}
                    >
                      {" "}
                      SIGN IN{" "}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item>
                    <Button
                      className="signup-btn"
                      loading={isSubmitting}
                      onClick={handleback}
                    >
                      {" "}
                      Back{" "}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              <p className="separator-line">
                <span className="text-dark">OR</span>
              </p>
              <Row justify="space-evenly">
                <Col>
                  <Button
                    className="azue-sigin-btn"
                    onClick={() => setOpen(true)}
                  >
                    <Icon
                      icon="logos:microsoft-icon"
                      style={{ marginRight: "4px" }}
                    />{" "}
                    Microsoft
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        {/* <Row className="mobile-banner-logo">
          <Col>
            <span className="signup-here-prefix">Don't have an account?</span>{" "}
            <span className="signup-here" onClick={hanldesignup}>
              Sign up here
            </span>
          </Col>
        </Row> */}
        <Modal
          title="Login With Microsoft"
          open={open}
          onCancel={handleCancel}
          footer={null}
          maskClosable={false}
          className="login-azure-ad-model"
        >
          {azureerrorstatus && (
            <Alert
              message=" Sorry, your email address is not available for this service. Please make sure you're using a valid email address and try again."
              type="error"
              closable
            />
          )}

          <Form onFinish={onFinishAzureAd} className="mt-5">
            <Form.Item
              name="email"
              label="Email"
              className="lable-white"
              rules={[
                { required: true, message: "Please input your email!" },
                {
                  type: "email",
                  message: "Please enter a valid email address!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item className="text-right">
              <Button
                htmlType="submit"
                className="plat-login-button"
                loading={isSubmitting}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Forgot Password"
          open={openforgotpassword}
          // onOk={handleOk}
          // confirmLoading={confirmLoading}
          onCancel={() => {
            setOpenForgotPassword(false);
          }}
          // visible={open}
          footer={null}
          maskClosable={false}
          className="login-azure-ad-model"
        >
          {formforgotstatus ? (
            <>
              <Form onFinish={onFinishForgotPasswordOTP} className="mt-4">
                <Form.Item
                  name="email"
                  label="Email"
                  className="lable-white"
                  rules={[
                    { required: true, message: "Please input your email!" },
                    {
                      type: "email",
                      message: "Please enter a valid email address!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item className="text-right">
                  <Button
                    htmlType="submit"
                    className="plat-login-button"
                    loading={isSubmitting}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </>
          ) : (
            <>
              <Form onFinish={onFinishForgotPasswordSubmit} className="mt-2">
                <Form.Item
                  name="verification_code"
                  label="Verification Code"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Verification Code",
                    },
                  ]}
                >
                  <Input
                    placeholder="Please Enter Verification Code"
                    className="form-control signin border-0"
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="Password"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Password",
                    },
                  ]}
                >
                  <Input
                    type="password"
                    placeholder="Please Enter Password"
                    className="form-control signin border-0"
                  />
                </Form.Item>

                <Form.Item
                  name="confirmpassword"
                  label="Confirm Password"
                  dependencies={["password"]}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    validatePassword,
                  ]}
                >
                  <Input
                    type="password"
                    placeholder="Please Enter Confirm Password"
                    className="form-control signin border-0"
                  />
                </Form.Item>

                <Form.Item className="text-right">
                  <Button
                    htmlType="submit"
                    className="plat-login-button"
                    loading={isSubmitting}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
        </Modal>
      </div>
    </>
  );
};
export default MobileSignin;
