import { memo } from "react";
import CreditUsage from "./CreditUsage";
import PromptUsage from "./PromptUsage";
// import ModalUsageTrend from "./ModalUsageTrend";
import UsageTrend from "./UsageTrend";
import React from "react";
import NavBar from "../../Header/NavBar";
import { Col, Row } from "antd";
import Sidemenu from "../../Sidemenu/Sidemenu";

const DashboardDesktop = () => {
  return (
    <>
      <NavBar />
      <Row>
        <Col xs={24} sm={24} md={24} lg={2} xl={2} className="body-margin-top">
          <Sidemenu />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={22}
          xl={22}
          className="body-margin-top aivolvex-dashboard-body"
        >
          <CreditUsage />
          {/* <ModalUsageTrend /> */}
          <PromptUsage />
          <UsageTrend />
        </Col>
      </Row>
    </>
  );
};
export default memo(DashboardDesktop);
