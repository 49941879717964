import React, { useEffect, useState } from "react";
import { Button, Modal, Input, message, Form, Rate } from "antd";
import { useDispatch } from "react-redux";
import { nsertNewFeedback } from "../../api/accountAction";
const { TextArea } = Input;

const FeedbackModel = (props) => {
    const [form] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const desc = ["terrible", "bad", "normal", "good", "wonderful"];
    const [value, setValue] = useState(3);
    const dispatch = useDispatch();
    const companyName = atob(
        localStorage.getItem("AIVolveXIdentityServiceProvider.com")
    );
    const userName = atob(
        localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
    );

    const handleCancel = () => {
        props.setOpen(false);
    };
    useEffect(() => {
        form.resetFields();
    }, []);
    const onFinish = (val) => {
        setConfirmLoading(true);
        const input = {
            rating: val.rating,
            feedback: val.feedback,
            avatar_id: props.avatarid,
            user_id: userName,
            question_id: "1",
            company: companyName,
        };
        dispatch(nsertNewFeedback(input))
            .unwrap()
            .then(({ data }) => {
                form.resetFields();
                message.success("Thank you for sharing your feedback with us!");
                props.setOpen(false);
                setConfirmLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setConfirmLoading(false);
            });
    };
    return (
        <>
            <Modal
                title="Provide additional feedback"
                open={props.open}
                // onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                footer={
                    [
                        // <Button
                        //   key="submit"
                        //   type="primary"
                        //   loading={confirmLoading}
                        //   onClick={handleOk}
                        //   className="button-primary btn btn-primary fontSize10"
                        // >
                        //   Submit
                        // </Button>,
                    ]
                }
            >
                <Form name="myForm" onFinish={onFinish}>
                    <Form.Item
                        label=""
                        name="rating"
                        rules={[{ required: true, message: "Please select a rating" }]}
                    >
                        <Rate
                            className="custom-star-color"
                            tooltips={desc}
                            onChange={setValue}
                            value={value}
                        />
                    </Form.Item>

                    <Form.Item
                        label=""
                        name="feedback"
                        rules={[{ required: true, message: "" }]}
                    >
                        <TextArea
                            rows={4}
                            placeholder="What do you like about the response?"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="button-primary btn btn-primary fontSize10"
                            loading={confirmLoading}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default FeedbackModel;
