import React, { useState } from "react";
import { FaLinkedinIn } from "react-icons/fa";
import { GoogleLogin } from "@react-oauth/google";
// import { Button } from "react-bootstrap";
import { message, Button, Modal, Form, Input, Alert } from "antd";
import "./login.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  loginEmailcheck,
  azureAdEmailCheck,
} from "../../components/api/accountAction";
import aivolvex from "../../assets/ui/X Icon SVG.svg";
import aivolvexlogo from "../../assets/ui/Logo SVG.svg";
import { Icon } from "@iconify/react";
import {
  cognitoSignIn,
  forgotPassword,
  submitForgotPassword,
} from "../Amplify/auth-config";

const SignIn = ({
  handleSignUpClick,
  emailidVerification,
  onFinishAzureAd,
  email,
  setEmail,
  password,
  setPassword,
  setIsSubmitting,
  isSubmitting,
  azureerrorstatus,
  setAzureErrorStatus,
  onFinishForgotPasswordSubmit,
  validatePassword,
  onFinishForgotPasswordOTP,
  forgotemail,
  setForgotEmail,
  setOpenForgotPassword,
  openforgotpassword,
  formforgotstatus,
  setFormForgotStatus,
  azureaderrormessage,
}) => {
  const navigateFn = useNavigate();
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  // const [buttonloader, setButtonLoader] = useState(false);
  const [form] = Form.useForm();
  //azure ad
  const [open, setOpen] = useState(false);
  // const [azureerrorstatus, setAzureErrorStatus] = useState(false);
  // const [isSubmitting, setIsSubmitting] = useState(false);
  const handleCancel = () => {
    setOpen(false);
  };
  const hanldesignup = () => {
    navigateFn("./signup");
  };
  // const onFinish = () => {
  //   var email_id = email;
  //   if (password === "EtPefa6uHn92cvB") {
  //     emailidVerification(email_id);
  //   } else {
  //     message.error("Invalid Credentials");
  //   }
  // };

  return (
    <>
      <div className="main-container">
        <div className="login-padding mobileView">
          <div className="text-center">
            {" "}
            {/* <img
            src={navLogo}
            className="navLogoSignIn"
            onClick={() => navigate("/")}
          />{" "} */}
          </div>
          <div className="mobileHeader">
            <div className="mt-2 sign-in-subheader mobile-logo">
              <img
                src={aivolvexlogo}
                alt="logo"
                className="mobile_signin_logo"
              />
            </div>
            <div className="mt-2 sign-in-subheader desktop-logo">
              <img src={aivolvex} alt="logo" className="signin_logo" />
              {/* <span className="logo-peta-login">beta</span> */}
            </div>
            <div className="mt-1">
              <p className="signin-header aivolvex-font"> Let's Get Started </p>
            </div>
          </div>
          <div className="mt-4">
            <Button
              type="button"
              htmlType="submit"
              className="me-1 plat-login-azure-btn"
              onClick={() => setOpen(true)}
            >
              <Icon
                icon="logos:microsoft-icon"
                style={{ marginRight: "4px" }}
              />{" "}
              Microsoft
            </Button>
          </div>
          <div className="mt-4"></div>
          <p className="separator-line pt-3 mt-3">
            <span className="text-dark">OR</span>
          </p>
          {/* <div className="mt-4 w-100 row">
          <div className="col-md-6 col-lg-6 w-50"> */}
          <div className="d-lg-flex w-100 mt-3 socials-div-mobile">
            <div className="linkedInButtonDiv margin-right1 linkedInButtonDiv-mobile">
              {/* <Button
                className=" signin-button-linkedin linkedInButtonDesktop"
                variant="primary"
                //   onClick={loginUsingLinkedIn}
              >
                <FaLinkedinIn className="" /> &nbsp;&nbsp;&nbsp;
                <span className="font-align-center ">Login using LinkedIn</span>
              </Button> */}

              {/* <Button
                className=" signin-button-linkedin linkedInButtonMobile"
                variant="primary"
                //   onClick={loginUsingLinkedIn}
              >
                <FaLinkedinIn className="" /> &nbsp;&nbsp;
                <span className="font-align-center "> LinkedIn</span>
              </Button> */}
            </div>
            {/* <div className="GoogleButtonDiv googlebuttonDiv-mobile">
              <GoogleLogin
                className="gooogle"
                //   onSuccess={onSuccess}
                //   onFailure={onFailure}
                cookiePolicy={"single_host_origin"}
              />
            </div> */}
          </div>
          {/* <p className="separator-line pt-3 mt-3">
            <span className="text-dark">OR</span>
          </p> */}

          <div>
            {/* <form onSubmit={handleSubmit(onSubmit)}> */}
            <div className="mb-3 mt-2 pt-2">
              <label className="pb-1 signIn-font"> Email ID</label>
              <input
                type="text"
                name="email"
                className="form-control signin"
                placeholder="Enter Mail ID"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                // {...register("username", {
                //   required: "Email Id is required",
                //   pattern: {
                //     value: /\S+@\S+\.\S+/,
                //     message: "Entered value does not match email format",
                //   },
                // })}
              />
              {/* {errors.username && (
                <span className="text-danger smaller-text" role="alert">
                  {errors.username.message}
                </span>
              )} */}
            </div>

            <div className="mb-3">
              <label className="pb-1 signIn-font">Password</label>
              <div className="d-flex">
                <input
                  type="password"
                  name="password"
                  className="form-control signin border-0"
                  placeholder="Enter Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  //   onKeyUp={() => passwordFieldKeyUp()}
                  //   onChange={(e) => setPassword(e.target.value)}
                  //   {...register("password", {
                  //     required: "Password is required",
                  //   })}
                />
                {/* {showPasswordEyeIcon ? (
                  <div
                    className="password-icon w-25 mt-2 me-2"
                    onClick={handleTogglePassword}
                  >
                    {showPassword ? (
                      <span style={{ float: "right" }}>
                        {" "}
                        <FaEyeSlash />{" "}
                      </span>
                    ) : (
                      <span style={{ float: "right" }}>
                        {" "}
                        <FaEye />{" "}
                      </span>
                    )}
                  </div>
                ) : (
                  <> </>
                )} */}
              </div>

              {/* {errors.password && (
                <span className="text-danger smaller-text" role="alert">
                  {errors.password.message}
                </span>
              )} */}
            </div>
            <div className="mb-3">
              <div className="custom-control custom-checkbox d-flex">
                <div className="d-flex-remember-w">
                  {/* <div className="w-50 remember-me">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customCheck1"
                    />
                    <label className="custom-control-label ps-1">
                      <span className="align-center-rememberme signIn-font-remeber aivolvex-font">
                        Remember me
                      </span>
                    </label>
                  </div> */}
                </div>
                <div className="forgotPasswordMobileView w-50">
                  <p
                    className="forgot-password text-right float-end cursor-pointer signIn-font-remeber aivolvex-font"
                    onClick={() => {
                      setOpenForgotPassword(true);
                      setFormForgotStatus(true);
                      const records = {
                        email: "",
                      };
                      form.setFieldsValue(records);
                    }}
                  >
                    Forgot password?
                  </p>
                </div>
              </div>
            </div>
            <div className="d-grid pt-2">
              {/* <Link to="/discover-gigs" className="d-flex justify-content-center"> */}
              <Button
                type="submit"
                className="button-primary btn btn-primary-new fontSize10"
                // onClick={() => navigateFn("/library")}
                // loading={buttonloader}
                loading={isSubmitting}
                onClick={emailidVerification}
              >
                SIGN IN
              </Button>
            </div>
            {/* <div className="web-signup-here-btn">
              <span className="signup-here-web-prefix">
                Don't have an account?
              </span>{" "}
              <span
                className="signup-web-here cursor-pointer"
                onClick={hanldesignup}
              >
                Sign up here
              </span>
            </div> */}

            {/* <div className="d-grid pt-2">
              <Button
                type="submit"
                className="button-primary btn btn-primary-new fontSize10"
                // onClick={() => navigateFn("/library")}
                loading={buttonloader}
                onClick={testCognitoRegister}
              >
                Register
              </Button>
            </div> */}
            {/* </form> */}

            {/* <div className="text-center mt-4 ">
            <p className="separator-line">
              <span className="signup-option">Don't have an account?</span>
            </p>
          </div> */}
          </div>
          {/* <div className="d-flex justify-content-center mt-4">
          <Button
            onClick={handleSignUpClick}
            className="button button-secondary signin"
            variant="primary"
          >
            Sign Up
          </Button>
        </div> */}
        </div>
      </div>

      <Modal
        title="Login With Microsoft"
        open={open}
        // onOk={handleOk}
        // confirmLoading={confirmLoading}
        onCancel={handleCancel}
        // visible={open}
        footer={null}
        maskClosable={false}
        className="login-azure-ad-model"
      >
        {azureerrorstatus && (
          <Alert message={azureaderrormessage} type="error" closable />
        )}

        <Form onFinish={onFinishAzureAd} className="mt-5">
          <Form.Item
            name="email"
            label="Email"
            className="lable-white"
            rules={[
              { required: true, message: "Please input your email!" },
              { type: "email", message: "Please enter a valid email address!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item className="text-right">
            <Button
              htmlType="submit"
              className="plat-login-button"
              loading={isSubmitting}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* forgot password */}
      <Modal
        title="Forgot Password"
        open={openforgotpassword}
        // onOk={handleOk}
        // confirmLoading={confirmLoading}
        onCancel={() => {
          setOpenForgotPassword(false);
        }}
        // visible={open}
        footer={null}
        maskClosable={false}
        className="login-azure-ad-model"
      >
        {formforgotstatus ? (
          <>
            <Form onFinish={onFinishForgotPasswordOTP} className="mt-4">
              <Form.Item
                name="email"
                label="Email"
                className="lable-white"
                rules={[
                  { required: true, message: "Please input your email!" },
                  {
                    type: "email",
                    message: "Please enter a valid email address!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item className="text-right">
                <Button
                  htmlType="submit"
                  className="plat-login-button"
                  loading={isSubmitting}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </>
        ) : (
          <>
            <Form onFinish={onFinishForgotPasswordSubmit} className="mt-2">
              <Form.Item
                name="verification_code"
                label="Verification Code"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Verification Code",
                  },
                ]}
              >
                <Input
                  placeholder="Please Enter Verification Code"
                  className="form-control signin border-0"
                />
              </Form.Item>

              <Form.Item
                name="password"
                label="Password"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Password",
                  },
                ]}
              >
                <Input
                  type="password"
                  placeholder="Please Enter Password"
                  className="form-control signin border-0"
                />
              </Form.Item>

              <Form.Item
                name="confirmpassword"
                label="Confirm Password"
                dependencies={["password"]}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  validatePassword,
                ]}
              >
                <Input
                  type="password"
                  placeholder="Please Enter Confirm Password"
                  className="form-control signin border-0"
                />
              </Form.Item>

              <Form.Item className="text-right">
                <Button
                  htmlType="submit"
                  className="plat-login-button"
                  loading={isSubmitting}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </Modal>
    </>
  );
};

export default SignIn;
