import { memo, useState } from "react";
import { Popconfirm, Row, Col } from "antd";
import eye_icon from "../../../assets/icons/eye.svg";
import message_icon from "../../../assets/icons/message.svg";
import star_icon from "../../../assets/icons/star.svg";
import price_icon from "../../../assets/icons/price.svg";
import token_icon from "../../../assets/icons/token.svg";
import chart_icon from "../../../assets/icons/chart.svg";
import { useSelector } from "react-redux";

const AvatarAbout = () => {
  const { avatardetails } = useSelector((state) => state.chat);
  return (
    <>
      <div className="d-flex justify-content-between">
        <div>
          <img
            src={avatardetails.avatar_image}
            alt="avatar-img"
            className="volvex-chat-header-avatar-logo"
          />
          <label className="margin-left-1">{avatardetails.avatar_name}</label>
        </div>
        <div>
          <Popconfirm
            placement="bottomRight"
            title={<PopconfirmAboutTitle />}
            description={<PopconfirmAboutDescription />}
            okText=""
            cancelText=""
            cancelButtonProps={{ style: { display: "none" } }}
            okButtonProps={{ style: { display: "none" } }}
            className="volvex-about-popconfirm"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 26 26"
              fill="none"
              className="cursor-pointer"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 12.5691C0 5.62736 5.62736 0 12.5691 0C19.5107 0 25.1381 5.62736 25.1381 12.5691C25.1381 19.5107 19.5107 25.1381 12.5691 25.1381C5.62736 25.1381 0 19.5107 0 12.5691ZM10.8317 7.3767C10.8317 6.4102 11.6152 5.6267 12.5817 5.6267H12.5937C13.5602 5.6267 14.3437 6.4102 14.3437 7.3767V7.38872C14.3437 8.35522 13.5602 9.13872 12.5937 9.13872H12.5817C11.6152 9.13872 10.8317 8.35522 10.8317 7.38872V7.3767ZM12.5817 11.0691C13.4101 11.0691 14.0817 11.7407 14.0817 12.5691V17.7614C14.0817 18.5898 13.4101 19.2614 12.5817 19.2614C11.7532 19.2614 11.0817 18.5898 11.0817 17.7614V12.5691C11.0817 11.7407 11.7532 11.0691 12.5817 11.0691Z"
                fill="white"
              />
            </svg>
          </Popconfirm>
        </div>
      </div>
    </>
  );
};

const PopconfirmAboutTitle = () => {
  return (
    <>
      <div className="d-flex justify-content-start">
        <div className="d-flex align-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 23 23"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.117188 11.3808C0.117188 5.09541 5.21247 0.00012207 11.4978 0.00012207C17.7832 0.00012207 22.8785 5.09541 22.8785 11.3808C22.8785 17.6661 17.7832 22.7614 11.4978 22.7614C5.21247 22.7614 0.117188 17.6661 0.117188 11.3808ZM10.158 6.71716C10.158 5.9829 10.7533 5.38765 11.4876 5.38765H11.4999C12.2342 5.38765 12.8294 5.9829 12.8294 6.71716V6.7295C12.8294 7.46377 12.2342 8.05901 11.4999 8.05901H11.4876C10.7533 8.05901 10.158 7.46377 10.158 6.7295V6.71716ZM11.4876 10.4947C11.9771 10.4947 12.3739 10.8916 12.3739 11.3811V16.0452C12.3739 16.5347 11.9771 16.9315 11.4876 16.9315C10.998 16.9315 10.6012 16.5347 10.6012 16.0452V11.3811C10.6012 10.8916 10.998 10.4947 11.4876 10.4947Z"
              fill="#D736D1"
            />
          </svg>
        </div>
        <div className="volvex-about-title d-flex align-items-center">
          <label>About</label>
        </div>
      </div>
    </>
  );
};

const PopconfirmAboutDescription = () => {
  const [viewallstatus, setViewAllStatus] = useState(true);
  const { avatardetails } = useSelector((state) => state.chat);
  return (
    <>
      <div className="volvex-about-body">
        <Row>
          <Col span={24}>
            <div className="volvex-about-avatar-logo-body">
              <img
                src={avatardetails.avatar_image}
                alt="avatar-img"
                className="volvex-about-avatar-logo"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label className="volvex-about-avatar-title">
              {avatardetails.avatar_name}
            </label>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="d-flex justify-content-center">
            <div className="avatar-info-icons">
              <span>
                <img src={token_icon} alt="token" className="avatar-info-img" />
              </span>
              <span className="info-icon-text">3000</span>
            </div>
            <div className="avatar-info-icons margin-left-1">
              <span>
                <img src={eye_icon} alt="eye" className="avatar-info-img" />
              </span>
              <span className="info-icon-text">5000</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <div
                className={viewallstatus ? "side-scroll-container-shadow" : ""}
                // className="side-scroll-container-shadow"
              >
                <div class="job-description word-wrap colorSecondary small-text white-color aivolvex-font mt-3 side-scroll-container">
                  {viewallstatus ? (
                    <>
                      {avatardetails.about_us?.length > 380 ? (
                        <>
                          {avatardetails.about_us?.substring(0, 380)}
                          ...{" "}
                        </>
                      ) : (
                        <>{avatardetails.about_us}</>
                      )}
                    </>
                  ) : (
                    <>{avatardetails.about_us}</>
                  )}
                </div>
                <div className="text-right right-padding-2">
                  {viewallstatus && (
                    <>
                      {/* {avatardetails.about_us?.length > 300 && (
                        <> */}
                      <span
                        className="avatar-view-more"
                        onClick={() => setViewAllStatus(false)}
                      >
                        Read more...
                      </span>
                      {/* </>
                      )} */}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="volvex-about-token-list">
          <Col span={6}>
            <div className="avatar-info-icons">
              <span>
                <img
                  src={message_icon}
                  alt="message"
                  className="avatar-info-img"
                />
              </span>
              <span className="info-icon-text">4200</span>
            </div>
          </Col>
          <Col span={6}>
            <div className="avatar-info-icons">
              <span>
                <img src={star_icon} alt="star" className="avatar-info-img" />
              </span>
              <span className="info-icon-text">4.8/5</span>
            </div>
          </Col>
          <Col span={6}>
            <div className="avatar-info-icons">
              <span>
                <img src={price_icon} alt="price" className="avatar-info-img" />
              </span>
              <span className="info-icon-text">$12000</span>
            </div>
          </Col>
          <Col span={6}>
            <div className="avatar-info-icons">
              <span>
                <img src={chart_icon} alt="chart" className="avatar-info-img" />
              </span>
              <span className="info-icon-text">950</span>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default memo(AvatarAbout);
