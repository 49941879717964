import { memo, useEffect, useState } from "react";
import "./Myprofile.scss";
import { Icon } from "@iconify/react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { getTokenRemaining, getUserController } from "../api/accountAction";
import { useDispatch, useSelector } from "react-redux";
import aivolvex from "../../assets/ui/X Icon SVG.svg";
import sidemenulogo from "../../assets/sidemenuicon.png";
import { IoShuffleOutline } from "react-icons/io5";
import { IoIosKeypad, IoIosTimer } from "react-icons/io";
import SidemenuDesktop from "./SidemenuDesktop";
import { setOfferStatus } from "../Slice/reducer/chatReducer";
const Sidemenu = (props) => {
  const [page, setpage] = useState("library");
  const [toggled, setToggled] = useState(false);
  const [avatarhubstatus, setAvatarHubStatus] = useState(false);
  const [offering, setOffering] = useState("");
  const [sidebarmenu, setSidebarMenu] = useState(1);
  const [fullpath, setFullPath] = useState("");
  const dispatch = useDispatch();
  const [token, setToken] = useState(0);
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const { offerstatus
  } = useSelector((state) => state.chat);
  useEffect(() => {
    setFullPath(window.location.pathname);
    if (window.location.pathname === "/conversation") {
      setSidebarMenu(1);
      setAvatarHubStatus(true);
    } else if (window.location.pathname === "/dashboard") {
      setSidebarMenu(2);
      setAvatarHubStatus(false);
    } else if (window.location.pathname === "/history") {
      setSidebarMenu(4);
      setAvatarHubStatus(false);
    } else if (window.location.pathname === "/recent_prompts") {
      setSidebarMenu(3);
      setAvatarHubStatus(false);
    } else if (window.location.pathname === "/favorites") {
      setSidebarMenu(5);
      setAvatarHubStatus(false);
    } else if (window.location.pathname === "/avatarhub/general") {
      setSidebarMenu(6);
    } else if (window.location.pathname === "/role") {
      setSidebarMenu(7);
    }
    else if (window.location.pathname === "/token") {
      setSidebarMenu(8);
    }
  }, [window.location.pathname, props]);
  useEffect(() => {
    const input = {
      company: companyName,
      user_id: atob(
        localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
      ),
    };
    // dispatch(getTokenRemaining(input))
    //   .unwrap()
    //   .then(({ data }) => {
    //     const records = JSON.parse(data.aivolvex_control_plane);
    //     setToken(records.remaining_credits);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     // message.error(err);  
    //   });

    dispatch(getUserController(input))
      .unwrap()
      .then(({ data }) => {
        // console.log(data);
        const records = JSON.parse(data.aivolvex_control_plane);
        setOffering(records.offering);

        if (records.offering == 'super admin') {
          // console.log(records.offering)
          dispatch(setOfferStatus(records.offering));
        }

      })
      .catch((err) => {
        console.log(err);
        // message.error(err);
      });
  }, []);
  return (
    <>
      {/* New Desktop side bar */}

      <div>
        <SidemenuDesktop sidebarmenu={sidebarmenu} offering={offering} />
      </div>
    </>
  );
};

export default memo(Sidemenu);
