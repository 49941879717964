import { memo, useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Space,
  Row,
  Col,
  AutoComplete,
  Popconfirm,
  message,
} from "antd";
import { Icon } from "@iconify/react";
import MembersListPopup from "./MembersListPopup";
import InputAutoComplete from "./InputAutoComplete";
import { updateTagging, deleteTagging } from "../api/accountAction";
import { useDispatch } from "react-redux";

const TagDetails = (props) => {
  const [open, setOpen] = useState(false);
  const [members_list, setMemberList] = useState([]);
  const [tagtitle, setTagTitle] = useState("");
  const [form] = Form.useForm();
  const [updatestatus, setUpdateStatus] = useState(false);
  const [entertitle, setEnterTitle] = useState(props.records.title);

  const dispatch = useDispatch();
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );

  const onFinish = (values) => {
    // const avatar
    var avatar;
    if (props.type === "avatar") {
      avatar = values.items?.map((item) => item.fieldName).join(",");
    } else {
      avatar = props.records.avatar_list?.map((item) => item.avatar).toString();
    }
    const filteredIds = props.avatarlist
      .filter((item) => avatar.includes(item.avatar_name))
      .map((item) => item.id);

    var member_list = props.records.members_list
      ?.map((item) => item.members_email)
      .toString();

    if (props.type === "member") {
      var new_member_list = [];
      values.items.map((item) => {
        if (item.fieldName !== "") {
          new_member_list.push(item.fieldName);
        }
      });
      member_list = new_member_list.toString();
    }

    const input = {
      id: values.id,
      company: companyName,
      user_id: userName,
      tag_title: entertitle,
      members_list: member_list,
      avatar_list: filteredIds.toString(),
      user_type: props.type === "member" ? "member.add" : "avatar.add",
    };
    dispatch(updateTagging(input))
      .unwrap()
      .then(({ data }) => {
        // console.log(data);
        const result = JSON.parse(data.aivolvex_control_plane);
        if (result.message) {
          if (result.message === "tag already exist") {
            message.error("Tag Already Exist");
          } else {
            props.setDataChangeStatus(!props.datachangestatus);
          }
        }
        // console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteClick = (id) => {
    const input = {
      id: id,
      company: companyName,
    };
    dispatch(deleteTagging(input))
      .unwrap()
      .then(({ data }) => {
        props.setDataChangeStatus(!props.datachangestatus);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // console.log(members_list)
  return (
    <>
      {open && (
        <MembersListPopup
          open={open}
          setOpen={setOpen}
          members_list={members_list}
          tagtitle={tagtitle}
        />
      )}
      {updatestatus ? (
        <Col xxl={6} xl={6} lg={6} md={8} sm={24} xs={24}>
          <Form
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 18,
            }}
            form={form}
            name="dynamic_form_complex"
            style={{
              maxWidth: 600,
            }}
            autoComplete="off"
            onFinish={onFinish}
            initialValues={{
              items:
                props.type === "avatar"
                  ? props.records.avatar_list?.map((data) => {
                      return {
                        fieldName: data.avatar,
                      };
                    })
                  : props.records.members_list?.map((data) => {
                      return {
                        fieldName: data.members_email,
                      };
                    }),
            }}
          >
            <Form.Item
              name="id"
              style={{ display: "none" }}
              initialValue={props.records.id}
            >
              <Input type="hidden" />
            </Form.Item>

            <Form.List name="items">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="edit-tag-card aivolvex-font">
                    <div className="tag-card-head d-flex justify-content-between">
                      <div>
                        <h3 className="tag-card-head-title">
                          <Input
                            placeholder="Tag Title"
                            className="edit-tag-add-input"
                            bordered={false}
                            value={entertitle}
                            onChange={(e) => {
                              setEnterTitle(e.target.value);
                            }}
                          />
                        </h3>
                      </div>
                      <div>
                        <Icon
                          icon="iconamoon:close"
                          className="tag-card-head-icon cursor-pointer"
                          onClick={() => {
                            setUpdateStatus(false);
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="tag-card-member-list">
                        <ul className="tag-card-list tag-card-list-scroll">
                          {fields.map((field, index, { ...restField }) => (
                            <>
                              {form.getFieldValue([
                                "items",
                                index,
                                "fieldName",
                              ]) ? (
                                <li className="edit-tag-list" key={field.key}>
                                  <div>
                                    {form.getFieldValue([
                                      "items",
                                      index,
                                      "fieldName",
                                    ]) || "Design 1"}
                                  </div>
                                  <div>
                                    <span className="edit-tag-close-icon">
                                      <Icon
                                        icon="iconamoon:close"
                                        onClick={() => {
                                          remove(field.name);
                                        }}
                                      />
                                    </span>
                                  </div>
                                </li>
                              ) : (
                                <li className="edit-tag-list" key={field.key}>
                                  <div>
                                    {props.type === "avatar" ? (
                                      <>
                                        <InputAutoComplete
                                          restField={restField}
                                          field={field}
                                          avatarlist={props.avatarlist}
                                        />
                                      </>
                                    ) : (
                                      <Form.Item
                                        {...restField}
                                        name={[field.name, "fieldName"]}
                                        fieldKey={[field.fieldKey, "fieldName"]}
                                        className="mar-0"
                                      >
                                        <Input
                                          placeholder="Type here"
                                          className="edit-tag-member-input"
                                        />
                                      </Form.Item>
                                    )}

                                    {/* <Form.Item
                                      {...restField}
                                      name={[field.name, "fieldName"]}
                                      fieldKey={[field.fieldKey, "fieldName"]}
                                      className="mar-0"
                                    >
                                      <Input
                                        placeholder="Type here"
                                        className="edit-tag-member-input"
                                      />
                                    </Form.Item> */}
                                  </div>
                                  <div>
                                    <span className="edit-tag-close-icon">
                                      <Icon
                                        icon="iconamoon:close"
                                        onClick={() => {
                                          remove(field.name);
                                        }}
                                      />
                                    </span>
                                  </div>
                                </li>
                              )}
                            </>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  {props.type === "member" ? (
                    <>
                      {" "}
                      <div className="tag-classic-bg d-flex justify-content-around">
                        <div>
                          <label
                            className="add-tag-label"
                            type="dashed"
                            onClick={() => add()}
                            block
                          >
                            Add Member
                          </label>
                        </div>
                        <div>
                          <Popconfirm
                            title="Are you sure?"
                            onConfirm={() =>
                              handleDeleteClick(props.records.id)
                            }
                            okText="Yes"
                            cancelText="No"
                          >
                            <label className="add-tag-label-delete">
                              Delete Tag
                            </label>
                          </Popconfirm>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="tag-classic-bg d-flex justify-content-around">
                      <div>
                        <label
                          className="add-tag-label"
                          type="dashed"
                          onClick={() => add()}
                          block
                        >
                          Add Agent
                        </label>
                      </div>
                    </div>
                  )}

                  <div className="tag-classic-bg tag-center">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="add-tag-btn"
                    >
                      Save Changes
                    </Button>
                  </div>
                </div>
              )}
            </Form.List>
          </Form>
        </Col>
      ) : (
        <Col xxl={6} xl={6} lg={6} md={8} sm={24} xs={24}>
          <div className="tag-card aivolvex-font">
            <div className="tag-card-head d-flex justify-content-between">
              <div>
                <h3 className="tag-card-head-title">
                  {props.records.title}

                  <span className="tag-card-head-sub-title">
                    {props.type === "avatar" ? (
                      <>({props.records.avatar_list?.length})</>
                    ) : (
                      <>({props.records.members_list?.length})</>
                    )}
                  </span>
                </h3>
              </div>
              <div>
                <Icon
                  icon="la:edit-solid"
                  className="tag-card-head-icon cursor-pointer"
                  onClick={() => {
                    setUpdateStatus(true);
                  }}
                />
              </div>
            </div>
            <div>
              {props.type === "avatar" ? (
                <div className="tag-card-member-list">
                  {/* <ul className="tag-card-list">
                    {props.records.avatar_list?.map((data) => (
                      <li>{data.avatar}</li>
                    ))}
                  </ul> */}
                  <ul className="tag-card-list">
                    {props.type === "avatar"
                      ? (props.records.avatar_list || [])
                          .slice(0, 5)
                          .map((data) => (
                            <li key={data.avatar}>{data.avatar}</li>
                          ))
                      : (props.records.members_list || [])
                          .slice(0, 5)
                          .map((data) => (
                            <li key={data.members_email}>
                              {data.members_email}
                            </li>
                          ))}
                  </ul>
                  {props.records.avatar_list?.length > 5 && (
                    <div className="tag-card-footer">
                      <div className="d-flex justify-content-between">
                        <div>
                          <label className="tag-card-footer-label">
                            & {props.records.avatar_list?.length - 5} More
                          </label>
                        </div>
                        <div>
                          <label
                            className="tag-card-footer-view-all"
                            onClick={() => {
                              setOpen(true);
                              setMemberList(props.records.avatar_list);
                              setTagTitle(props.records.title);
                            }}
                          >
                            View All
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="tag-card-member-list">
                  <ul className="tag-card-list tag-card-list-scroll">
                    {props.records.members_list?.slice(0, 5).map((data) => (
                      <li>{data.members_email}</li>
                    ))}
                  </ul>
                  {props.records.members_list?.length > 5 && (
                    <div className="tag-card-footer">
                      <div className="d-flex justify-content-between">
                        <div>
                          <label className="tag-card-footer-label">
                            & {props.records.members_list?.length - 5} More
                          </label>
                        </div>
                        <div>
                          <label
                            className="tag-card-footer-view-all"
                            onClick={() => {
                              setOpen(true);
                              setMemberList(props.records.members_list);
                              setTagTitle(props.records.title);
                            }}
                          >
                            View All
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </Col>
      )}
    </>
  );
};

export default memo(TagDetails);
