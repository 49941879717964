import React, { useState } from "react";
import mobilebanner3 from "../../assets/mobile-banner-3 (1).png";
import mobilebanner4 from "../../assets/mobile-banner-3 (2).png";
import { Icon } from "@iconify/react";
import { Button, Row, Col, Avatar, Form, Input, Checkbox } from "antd";
import "./mobilesignin.css";
import { useNavigate } from "react-router-dom";
const Mobileviewsignup = ({
  onFinishSubmitForm,
  handleSignUpClick,
  buttonloader,
  setButtonLoader,
  email,
  setEmail,
  registerstatus,
  setRegisterStatus,
  setInput,
  onOTPSubmitForm,
  resetOTP,
  validatePassword,
  validateConfirmPassword,
}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const onChange = (e) => {
    // console.log(`checked = ${e.target.checked}`);
  };
  const handleSubmit = (value) => {
    // console.log(value);/
    setInput(value);
    setEmail(value.email);
    onFinishSubmitForm(value);
    // setPassword(value.password)
    // emailidVerification(value);
  };
  const handleback = () => {
    // setShowMobileSignin(false)
    navigate("/");
  };
  const handleSignin = () => {
    navigate("/");
  };
  return (
    <>
      <div className="mobile-banner banner-bg">
        <Row>
          <Col className="mobile-col">
            <img src={mobilebanner3} />
          </Col>
        </Row>
        <Row className="mobile-banner-logo mobile-avatar  tab-avatar">
          <Col>
            <img src={mobilebanner4} className="avatar-logo" />
          </Col>
        </Row>
        <Row className="mobile-banner-logo">
          <Col>
            <span className="mobile-banner-text-normal">Let's Get Started</span>
          </Col>
        </Row>
        {registerstatus ? (
          <>
            <Row className="signin-form">
              <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Row>
                  <Col>
                    <Form.Item
                      label="First Name"
                      name="firstname"
                      rules={[{ required: true, message: "" }]}
                    >
                      <Input
                        placeholder="Enter your first name"
                        className="signin-form-input"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item
                      label="Last Name"
                      name="lastname"
                      rules={[{ required: true, message: "" }]}
                    >
                      <Input
                        placeholder="Enter your last name"
                        className="signin-form-input"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item
                      label="Email"
                      name="emailid"
                      rules={[{ required: true, message: "" }]}
                    >
                      <Input
                        placeholder="Enter your email"
                        className="signin-form-input"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Please Enter Password',
                        },
                         validatePassword,
               
                      ]}
                    >
                      <Input
                        type="password"
                        placeholder="Enter your password"
                        className="signin-form-input"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item
                      label="Confirm Password"
                      name="confirmpassword"
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        validateConfirmPassword,
                      ]}
                    >
                      <Input
                        type="password"
                        placeholder="Enter your confirm password"
                        className="signin-form-input"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col className="term-condition">
                    {/* <Checkbox onChange={onChange} className="text-checkbox">
                      <div>
                        <span className="signup-here-prefix term-text">
                          I agree to the{" "}
                        </span>
                        {""}
                        <span className="signup-here term-text">
                          Terms and Conditions{" "}
                        </span>
                        {""}
                        <span className="signup-here-prefix term-text">
                          of use and{" "}
                        </span>
                        {""}
                        <span className="signup-here term-text">
                          Privacy Policy{" "}
                        </span>
                      </div>
                    </Checkbox> */}

                    <input type="checkbox" className="text-checkbox" /></Col><Col>
                    <label > <span className="signup-here-prefix term-text">I agree to the </span>{""}<span className="signup-here term-text">Terms and Conditions </span>{""}<span className="signup-here-prefix term-text">of use and </span>{""}<span className="signup-here term-text">Privacy Policy </span>
                    </label><br></br>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item>
                      <Button className="signin-btn" htmlType="submit">
                        {" "}
                        SIGN UP{" "}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Row>
            <Row className="mobile-banner-logo">
              <Col>
                <Button
                  className="signup-btn"
                  loading={buttonloader}
                  onClick={handleback}
                >
                  {" "}
                  Back{" "}
                </Button>
              </Col>
            </Row>
            <Row className="mobile-banner-logo">
              <Col className="signup-footer">
                <span className="signup-here-prefix">
                  Already have an account?
                </span>{" "}
                <span className="signup-here" onClick={handleSignin}>
                  Sign in here
                </span>
              </Col>
            </Row>
          </>
        ) : (
          <Form onFinish={onOTPSubmitForm} className="mt-5">
            <Form.Item
              name="verification_code"
              label="Verification Code"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Please Enter Verification Code",
                },
              ]}
            >
              <Input
                placeholder="Please Enter Verification Code"
                className="form-control signin border-0"
              />
            </Form.Item>
            <div className="mb-3">
              <div className="custom-control custom-checkbox d-flex">
                <div className="d-flex-remember-w"></div>
                <div className="forgotPasswordMobileView w-50">
                  <p
                    className="forgot-password text-right float-end cursor-pointer signIn-font-remeber aivolvex-font"
                    onClick={resetOTP}
                  >
                    Didn't Receive OTP?
                  </p>
                </div>
              </div>
            </div>
            <Form.Item className="text-right">
              <Button
                htmlType="submit"
                className="button-primary btn btn-primary-new fontSize10 width-100"
                loading={buttonloader}
              >
                SUBMIT
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </>
  );
};
export default Mobileviewsignup;
