import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";

const SharedList = (props) => {
  const navigateFn = useNavigate();

  const getPromptsContent = (conversation_id) => {
    // console.log("/library/" + props.avatar_id + "/" + conversation_id);
    // navigateFn("/chat/" + props.avatar_id + "/" + conversation_id);
  };
  return (
    <>
      <div className="aivolvex-font">
        {/* <div className="text-center">
    <label className="prompt-tap-title">History </label>
  </div> */}
        <div className="side-scroll-container-prompts">
          {/* {promptslist.map((data) => { */}
          {/* return ( */}
          <button
            class="button-primary-questions  btn btn-primary fontSize10"
            role="button"
            // onClick={() => {
            //   getPromptsContent(data.conversation_id);
            // }}
          >
            <div className="btn-main-box d-flex justify-content-between">
              <div className="btn-prompts-content">
                <span>
                  <Icon icon="icons8:share" className="prompts-history-icon" />
                </span>
                {/* {data.title?.length > 70 ? (
                  <>{data.title?.substring(0, 70)}... </>
                ) : (
                  <>{data.title}</>
                )} */}
              </div>
            </div>
          </button>
          {/* ); */}
          {/* })} */}
        </div>
      </div>
    </>
  );
};

export default SharedList;
