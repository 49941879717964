// lastWordSlice.js

import { createSlice } from '@reduxjs/toolkit';

const lastWordSlice = createSlice({
  name: 'lastWord',
  initialState: '',
  reducers: {
    updateLastWord: (state, action) => {
      return action.payload;
    },
  },
});
export const { updateLastWord } = lastWordSlice.actions;

const sessionSlice = createSlice({
  name: 'session',
  initialState: '',
  reducers: {
    setSessionId: (state, action) => {
      return action.payload;
    },
  },
});

export const { setSessionId } = sessionSlice.actions;
export const sessionReducer = sessionSlice.reducer;

export default lastWordSlice.reducer;



