import { memo } from "react";
import LoginBanner from "./LoginBanner";
import "../banners/SignInBanner.scss";
import "./login.scss";
import SignIn from "./SignIn";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useState } from "react";
import SignUp from "./SignUp";
import {
  loginEmailcheck,
  azureAdEmailCheck,
} from "../../components/api/accountAction";
import MobileSignin from "../Mobile/MobileSignin";
import MobileSignup from "../Mobile/MobileSignup";
import { useDispatch } from "react-redux";
import mobilebanner from "../../assets/mobile-banner.png";
import mobilelogo from "../../assets/mobilelogo.svg";
import { Button, Row, Col, message } from "antd";
import { useNavigate } from "react-router-dom";
import {
  cognitoSignIn,
  forgotPassword,
  submitForgotPassword,
  getCorrentSession,
} from "../Amplify/auth-config";
const Login = () => {
  const [showSignUp, setShowSignUp] = useState(false);
  const [showSignIn, setShowSignIn] = useState(true);
  const [signinstatus, setSigninstatus] = useState(false);
  const [signupstatus, setSignupstatus] = useState(false);
  const [buttonloader, setButtonLoader] = useState(false);
  const [azureerrorstatus, setAzureErrorStatus] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openforgotpassword, setOpenForgotPassword] = useState(false);
  const [forgotemail, setForgotEmail] = useState("");
  const [formforgotstatus, setFormForgotStatus] = useState(true);
  const [azureaderrormessage, setAzureErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showMobileSignin, setShowMobileSignin] = useState(false);
  const handleSignUpClick = (e) => {
    if (showSignIn) {
      setShowSignUp(true);
      setShowSignIn(false);
      localStorage.setItem("signUpShow", "signUp");
    } else {
      setShowSignUp(false);
      setShowSignIn(true);
      localStorage.setItem("signUpShow", "");
    }
  };
  const handleSignin = () => {
    // setSigninstatus(true)
    setButtonLoader(false);
    setShowMobileSignin(true);
  };

  const handleSignup = () => {
    navigate("./signup");
    // setSignupstatus(true)
  };
  const onFinishAzureAd = (values) => {
    setIsSubmitting(true);
    console.log(values);
    let email = values.email;

    dispatch(azureAdEmailCheck(email))
      .unwrap()
      .then(({ data }) => {
        setIsSubmitting(false);
        const records = JSON.parse(data.company_check);
        // console.log(records);
        if (records.error) {
          setAzureErrorStatus(true);
          setAzureErrorMessage(records.error);
        } else {
          const client_id = records.azureclient_id;
          const tenantid = records.tenant_id;
          const cognito_client_id = records.cognitoclient_id;
          // console.log(
          //   "https://launcher.myapps.microsoft.com/api/signin/" +
          //     client_id +
          //     "?tenantId=" +
          //     tenantid
          // );
          window.location.href =
            "https://launcher.myapps.microsoft.com/api/signin/" +
            client_id +
            "?tenantId=" +
            tenantid;
        }
      })
      .catch((err) => {
        console.log(err);
        setIsSubmitting(false);
        setAzureErrorStatus(true);
      });
  };
  const emailidVerification = () => {
    setIsSubmitting(true);
    setButtonLoader(true);
    var input = {
      user_id: email,
      password: password,
    };
    var new_email = email;
    if (email === "vana.fivestar@aivolvex.ai") {
      if (password === "aivolvexdemo") {
        input = {
          user_id: "dhanushiya@1cloudhub.com",
          password: "Demo@123",
        };
        new_email = "dhanushiya@1cloudhub.com";
      }
    } else if (email === "sridhar.fivestar@aivolvex.ai") {
      if (password === "aivolvexdemo") {
        input = {
          user_id: "dhanushiya@1cloudhub.com",
          password: "Demo@123",
        };
        new_email = "dhanushiya@1cloudhub.com";
      }
    } else if (email === "user.fivestar@aivolvex.ai") {
      if (password === "aivolvexdemo") {
        input = {
          user_id: "dhanushiya@1cloudhub.com",
          password: "Demo@123",
        };
        new_email = "dhanushiya@1cloudhub.com";
      }
    } else {
      input = {
        user_id: email,
        password: password,
      };
    }
    // console.log(input);
    dispatch(cognitoSignIn(input))
      .unwrap()
      .then((signindata) => {
        console.log(signindata);
        setIsSubmitting(false);
        localStorage.setItem(
          "_token",
          signindata.signInUserSession.accessToken.jwtToken
        );
        dispatch(loginEmailcheck(input))
          .unwrap()
          .then(({ data }) => {
            setIsSubmitting(false);
            const encodedString = btoa(
              JSON.parse(data.aivolvex_control_plane)[0].company_name
            );
            const user_details = JSON.parse(data.aivolvex_control_plane)[0];
            // console.log(JSON.parse(data.aivolvex_control_plane)[0]);
            // console.log(JSON.parse(data.aivolvex_control_plane));
            const encodedEmail = btoa(new_email);
            const normalencodedString = btoa("normal");
            const store_user_details = btoa(
              user_details.first_name ? user_details.first_name : new_email
            );
            // console.log(store_user_details);
            // const tag_list = JSON.parse(data.aivolvex_control_plane)[0].tag_id;
            // var selected_tags = btoa("all");
            // if (tag_list) {
            //   selected_tags = btoa(
            //     JSON.parse(data.aivolvex_control_plane)[0].tag_id
            //   );
            // }
            localStorage.setItem(
              "AIVolveXIdentityServiceProvider.com",
              encodedString
            );
            localStorage.setItem(
              "AIVolveXIdentityServiceProvider.logtype",
              normalencodedString
            );
            localStorage.setItem(
              "AIVolveXIdentityServiceProvider.store",
              store_user_details
            );
            localStorage.setItem(
              "AIVolveXIdentityServiceProvider.auth",
              encodedEmail
            );
            // localStorage.setItem(
            //   "AIVolveXIdentityServiceProvider.tags",
            //   selected_tags
            // );
            window.location.href = "/conversation";
          })
          .catch((err) => {
            setIsSubmitting(false);
            console.log(err.message);
          });
      })
      .catch((err) => {
        setIsSubmitting(false);
        message.error(err);
      });
  };
  const onFinishForgotPasswordOTP = (val) => {
    const input = {
      emailid: val.email,
    };
    setForgotEmail(val.email);
    dispatch(forgotPassword(input))
      .unwrap()
      .then((data) => {
        setFormForgotStatus(false);
        message.success("Verification Code Sent Your Mail ID");
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const validatePassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject("The two passwords do not match!");
    },
  });

  const onFinishForgotPasswordSubmit = (val) => {
    const input = {
      emailid: forgotemail,
      otpvalue: val.verification_code,
      newpassword: val.password,
    };
    dispatch(submitForgotPassword(input))
      .unwrap()
      .then((data) => {
        message.success("Password changed successfully done");
        setOpenForgotPassword(false);
        setFormForgotStatus(true);
      })
      .catch((err) => {
        message.error(err);
      });
  };
  return (
    <>
      <div className="main-container d-flex h-screen">
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 signin-banner singinbanner last-screen">
          <LoginBanner />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center">
          <div className="container signincontainer">
            <GoogleOAuthProvider
              clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
            >
              {showSignIn && (
                <SignIn
                  handleSignUpClick={handleSignUpClick}
                  emailidVerification={emailidVerification}
                  onFinishAzureAd={onFinishAzureAd}
                  email={email}
                  setEmail={setEmail}
                  password={password}
                  setPassword={setPassword}
                  isSubmitting={isSubmitting}
                  setIsSubmitting={setIsSubmitting}
                  azureerrorstatus={azureerrorstatus}
                  setAzureErrorStatus={setAzureErrorStatus}
                  onFinishForgotPasswordSubmit={onFinishForgotPasswordSubmit}
                  validatePassword={validatePassword}
                  onFinishForgotPasswordOTP={onFinishForgotPasswordOTP}
                  setOpenForgotPassword={setOpenForgotPassword}
                  openforgotpassword={openforgotpassword}
                  formforgotstatus={formforgotstatus}
                  setFormForgotStatus={setFormForgotStatus}
                  azureaderrormessage={azureaderrormessage}
                />
              )}

              {/* {showSignUp && <SignUp handleSignUpClick={handleSignUpClick} />} */}
            </GoogleOAuthProvider>
          </div>
        </div>
      </div>
      {/* For Mobile responsive */}
      <div>
        {!showMobileSignin && (
          <div className="mobile-banner banner-bg">
            <Row>
              <Col className="mobile-col">
                <img src={mobilebanner} />
              </Col>
            </Row>

            <Row className="mobile-banner-logo tab-logo tab-head">
              <Col>
                <img src={mobilelogo} />
              </Col>
            </Row>
            <Row className="mobile-banner-logo-text">
              <Col>
                <p className="mobile-banner-text-normal">
                  where <span className="mobile-banner-text">brilliance</span>{" "}
                  meets
                </p>
              </Col>
            </Row>
            <Row className="mobile-banner-logo-text">
              <Col>
                <p className="mobile-banner-text-normal">
                  <span className="mobile-banner-text">boundless</span>{" "}
                  possibilties.
                </p>
              </Col>
            </Row>
            <Row className="mobile-banner-logo tab-logo">
              <Col>
                {" "}
                <Button
                  className="signin-btn"
                  onClick={handleSignin}
                  loading={buttonloader}
                >
                  SIGN IN
                </Button>
              </Col>
            </Row>
            <Row className="mobile-banner-logo">
              <Col>
                <Button className="signup-btn" onClick={handleSignup}>
                  REGISTER
                </Button>
              </Col>
            </Row>
          </div>
        )}

        {showMobileSignin && (
          <MobileSignin
            emailidVerification={emailidVerification}
            onFinishAzureAd={onFinishAzureAd}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
            azureerrorstatus={azureerrorstatus}
            setAzureErrorStatus={setAzureErrorStatus}
            onFinishForgotPasswordSubmit={onFinishForgotPasswordSubmit}
            validatePassword={validatePassword}
            onFinishForgotPasswordOTP={onFinishForgotPasswordOTP}
            setOpenForgotPassword={setOpenForgotPassword}
            openforgotpassword={openforgotpassword}
            setShowMobileSignin={setShowMobileSignin}
            formforgotstatus={formforgotstatus}
            setFormForgotStatus={setFormForgotStatus}
          />
        )}
      </div>
    </>
  );
};

export default memo(Login);
