import React, { useEffect, useState } from "react";
import {
    Col,
    Row,
    Input,
    Card,
    Form,
    Upload,
    Button,
    Select,
    ColorPicker,
} from "antd";
const Profileform = () => {
    const [primaryvalue, setprimaryValue] = useState("#db2aac");
    const [secondaryvalue, setsecondaryValue] = useState("#3A3D63");
    const [fontcolorvalue, setfontcolorValue] = useState("#db2aac");
    const [cardcolorvalue, setcardcolorValue] = useState("#D736D1");
    const [sidecolorvalue, setsidecolorValue] = useState("#848484");
    const [selectedTheme, setSelectedTheme] = useState("default");
    const onFinish = (values) => {
        const inputValues = {
            companyName: values.companyname,
            companylogo: values.companylogo,
            websiteTheme: values.websitetheme,
            primaryColor: primaryvalue,
            secondaryColor: secondaryvalue,
            fontColor: fontcolorvalue,
            cardColor: cardcolorvalue,
            sidebarColor: sidecolorvalue,
            preloaderImage: values.preloader,
            chatBackground: values.chatbg,
            conversationFontSize: values.fontsize,
        };
        // console.log("Form values:", inputValues);
    };
    const layout = {
        labelCol: {
            span: 10,
        },
        wrapperCol: {
            span: 14,
        },
    };
    const [form] = Form.useForm();

    return (
        <>
            {/* <Row className="recent-body"> */}
            <Row className="pro-body">
                <Col
                    className="aivolvex-profile-outline card-design"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                >
                    <Card
                        title="Company Profile"
                        bordered={false}
                        className="profile-card"
                    >
                        <Form
                            {...layout}
                            form={form}
                            name="control-ref"
                            onFinish={onFinish}
                            style={{
                                maxWidth: 600,
                            }}
                        >
                            <Form.Item
                                name="companyname"
                                label="Company Name "
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="companylogo"
                                label="Company Logo"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Upload>
                                    <Button>Click to Upload image</Button>
                                </Upload>
                            </Form.Item>
                            <Form.Item
                                name="websitetheme"
                                label="Website Theme"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select a theme"
                                    optionFilterProp="children"
                                    onChange={(value) => {
                                        setSelectedTheme(value);
                                        // console.log(selectedTheme);
                                    }}
                                    options={[
                                        {
                                            value: "default",
                                            label: "Default",
                                        },
                                        {
                                            value: "lighttheme",
                                            label: "Light Theme",
                                        },
                                        {
                                            value: "customtheme",
                                            label: "Custom Theme",
                                        },
                                    ]}
                                />
                            </Form.Item>
                            {selectedTheme === "customtheme" && (
                                <div>
                                    <Form.Item
                                        name="primarycolor"
                                        label="Primary Color "
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        {" "}
                                        <ColorPicker
                                            onChange={(color) => {
                                                const colorCode = color.toHexString();
                                                setprimaryValue(colorCode);
                                            }}
                                            size="large"
                                            showText
                                        />
                                    </Form.Item>
                                    <Form.Item name="secondarycolor" label="Secondary Color ">
                                        {" "}
                                        <ColorPicker
                                            onChange={(color) => {
                                                const colorCode = color.toHexString();
                                                setsecondaryValue(colorCode);
                                            }}
                                            size="large"
                                            showText
                                        />
                                    </Form.Item>
                                    <Form.Item name="fontcolor" label="Font Color ">
                                        {" "}
                                        <ColorPicker
                                            onChange={(color) => {
                                                const colorCode = color.toHexString();
                                                setfontcolorValue(colorCode);
                                            }}
                                            size="large"
                                            showText
                                        />
                                    </Form.Item>
                                    <Form.Item name="cardcolor" label="Card Color ">
                                        {" "}
                                        <ColorPicker
                                            onChange={(color) => {
                                                const colorCode = color.toHexString();
                                                setcardcolorValue(colorCode);
                                            }}
                                            size="large"
                                            showText
                                        />
                                    </Form.Item>
                                    <Form.Item name="sidebarcolor" label="Sidebar Color ">
                                        {" "}
                                        <ColorPicker
                                            onChange={(color) => {
                                                const colorCode = color.toHexString();
                                                setsidecolorValue(colorCode);
                                            }}
                                            size="large"
                                            showText
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="preloader"
                                        label="Preloader Image"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Upload>
                                            <Button>Click to Upload image</Button>
                                        </Upload>
                                    </Form.Item>
                                    <Form.Item
                                        name="chatbg"
                                        label="Chat background"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Upload>
                                            <Button>Click to Upload image</Button>
                                        </Upload>
                                    </Form.Item>
                                    <Form.Item
                                        name="fontsize"
                                        label="Conversation fontsize"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter a numerical value.",
                                                pattern: /^[0-9]+$/,
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>
                            )}
                            <Form.Item
                                wrapperCol={{
                                    offset: 8,
                                    span: 16,
                                }}
                                className="submit-button-form"
                            >
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </>
    );
};
export default Profileform;
