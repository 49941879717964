import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  avatardetails: [],
  chatmessage: [],
  libraryquestion: "",
  chat_session_id: "",
  temp_chat_session_id: "",
  pinnedstatus: false,
  offerstatus: "",
  chatpreloader: false,
  creditstokenstatus: false,
  dashbaorddate: `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`,
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    avatarDetails: (state, { payload }) => {
      state.avatardetails = payload;
    },
    setChatMessage: (state, { payload }) => {
      state.chatmessage = [...state.chatmessage, payload];
    },
    deleteChatMessage: (state) => {
      state.chatmessage = state.chatmessage.slice(0, -1);
    },
    removeHistoryChatMessage: (state) => {
      state.chatmessage = [];
    },
    setLibraryQuestion: (state, { payload }) => {
      state.libraryquestion = payload;
    },
    setChatSessionID: (state, { payload }) => {
      state.chat_session_id = payload;
    },
    setTempChatSessionID: (state, { payload }) => {
      state.temp_chat_session_id = payload;
    },
    setPinnedStatus: (state, { payload }) => {
      state.pinnedstatus = payload;
    },
    setOfferStatus: (state, { payload }) => {
      state.offerstatus = payload;
    },
    setChatPreLoader: (state, { payload }) => {
      state.chatpreloader = payload;
    },
    setDashboardDate: (state, { payload }) => {
      state.dashbaorddate = payload;
    },
    setCreditsTokenStatus: (state, { payload }) => {
      state.creditstokenstatus = payload;
    },
  },
});

export default chatSlice.reducer;
export const {
  avatarDetails,
  setChatMessage,
  deleteChatMessage,
  setLibraryQuestion,
  setChatSessionID,
  setTempChatSessionID,
  removeHistoryChatMessage,
  setPinnedStatus,
  setOfferStatus,
  setChatPreLoader,
  setDashboardDate,
  setCreditsTokenStatus,
} = chatSlice.actions;
