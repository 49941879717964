import { Col, Row } from "antd";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { getmodalchart } from "../../api/accountAction";
import { simplifyNumber } from "../../customhook/CustomHoom";
// function simplifyNumber(number) {
//   const billion = 1000000000;
//   const million = 1000000;
//   const thousand = 1000;

//   if (number >= billion) {
//     return (number / billion).toFixed(1) + 'B';
//   } else if (number >= million) {
//     return (number / million).toFixed(1) + 'M';
//   } else if (number >= thousand) {
//     return (number / thousand).toFixed(1) + 'K';
//   } else {
//     return number.toString();
//   }
// }
const ModelCredits = () => {

  const dispatch = useDispatch();
  const { dashbaorddate, offerstatus } = useSelector((state) => state.chat);
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );
  const [modallist, setModallist] = useState([]);


  useEffect(() => {

    const input = {
      event_type: "model_wise_question_count",
      company: companyName,
      user_id: userName,
      date_time: dashbaorddate,
      user_type: offerstatus,
    };

    dispatch(getmodalchart(input))
      .unwrap()
      .then(({ data }) => {
        const modalquestion = JSON.parse(data.aivolvex_control_plane);
        // console.log(modalquestion, "modalques")
        const totle_sum = modalquestion.reduce((total, num) => +total + +num.total_tokens, 0);

        if (modalquestion.length > 0) {
          var final_result = [];
          modalquestion.map((model_data) => {
            final_result.push({
              model: model_data.model_id,
              count: model_data.question_count,
              token: model_data.total_tokens,
              share: Math.round((model_data.total_tokens * 100) / totle_sum)
            })
          })
          setModallist(final_result);

        }

      })

      .catch((err) => {
        console.log(err);
      });
  }, [dashbaorddate, offerstatus]);


  return (
    <>
      <Row >
        <Col span={8}>
          <h1 className="aivolvex-dashboard-model-token">Model</h1>
        </Col>
        <Col span={4}>
          <h1 className="aivolvex-dashboard-model-token aivolvex-right">
            Count
          </h1>
        </Col>
        <Col span={6}>
          <h1 className="aivolvex-dashboard-model-token aivolvex-right">
            Credit
          </h1>
        </Col>
        <Col span={6}>
          <h1 className="aivolvex-dashboard-model-token aivolvex-right">
            Share
          </h1>
        </Col>
      </Row>
      <div className="aivolvex-dashboard-modeltoken-scroll">
        {
          modallist.map((result) => {
            return <Row className="mt-2">
              <Col span={8}>
                <label className="aivolvex-dashboard-model-token">{result.model}
                </label>
              </Col>
              <Col span={4} className="aivolvex-right">
                <label className="aivolvex-dashboard-model-token">{simplifyNumber(result.count)}</label>
              </Col>
              <Col span={6} className="aivolvex-right">
                <label className="aivolvex-dashboard-model-token">{simplifyNumber(result.token)}</label>
              </Col>
              <Col span={6} className="aivolvex-right">
                <label className="aivolvex-dashboard-model-token">{result.share}% </label>
              </Col>
            </Row>
          })
        }

      </div>
    </>
  );
};

export default memo(ModelCredits);
