import { memo } from "react";
import { Modal } from "antd";

const MembersListPopup = (props) => {

  return (
    <>
      <Modal
        title={`${props.tagtitle} (${props.members_list.length})`}
        open={props.open}
        onCancel={() => {
          props.setOpen(false);
        }}
        // visible={open}
        footer={null}
        maskClosable={false}
        className="tag-card-members-list"
      >
        {props.members_list?.map((data) => (
          <>
            <p className="pop-members-list">{data.members_email}</p>
            <p className="pop-members-list">{data.avatar}</p>
          </>

        ))}
      </Modal>
    </>
  );
};

export default memo(MembersListPopup);
