import { Col, Row, Card } from "antd";
// import avator_img from "../../assets/ui/Avatar 1.svg";

const FavoritesAvatar = () => {
  return (
    <>
      <Row className="mt-4" gutter={[16, 16]}>
        <Col span={12}>
          <Card className="avator-list filterResultCard">
            <Row>
              <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                {/* <img src={avator_img} alt="" className="companyLogoImage" /> */}
              </Col>
              <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                <p class="labelStyle-title aivolvex-font">CloudShifu</p>
                <p class="labelStyle aivolvex-font">
                  Greetings, esteemed cloud voyagers! I am CloudShifu, your
                  trusted guide through the celestial expanse of enterprise
                  cloud estates 🚀. As a virtual cloud guru, I possess the power
                  to unveil the deepest insights hidden within your cloud assets
                  monitori...
                  {/* {props.data.about_us.length > 250 ? (
                    <>{props.data.about_us.substring(0, 250)}...</>
                  ) : (
                    <>{props.data.about_us}</>
                  )} */}
                </p>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                className="text-right"
              >
                <button
                  class="button-primary btn btn-primary-new fontSize10"
                  role="button"
                >
                  Explore
                </button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={12}>
          <Card className="avator-list filterResultCard">
            <Row>
              <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                {/* <img src={avator_img} alt="" className="companyLogoImage" /> */}
              </Col>
              <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                <p class="labelStyle-title aivolvex-font">CostDeMaze</p>
                <p class="labelStyle aivolvex-font">
                  Hello there! Im FinVizor, your virtual financial guide 🚀. As
                  an AI-powered financial insights avatar, my mission is to
                  demystify complex financial reports and provide you with
                  clear, concise, and actionable insights into companies
                  financial performa...
                  {/* {props.data.about_us.length > 250 ? (
                    <>{props.data.about_us.substring(0, 250)}...</>
                  ) : (
                    <>{props.data.about_us}</>
                  )} */}
                </p>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                className="text-right"
              >
                <button
                  class="button-primary btn btn-primary-new fontSize10"
                  role="button"
                >
                  Explore
                </button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default FavoritesAvatar;
