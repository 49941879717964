import React, { useEffect, useState } from "react";
import { Col, Row, Input, Card } from "antd";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import NavBar from "../Header/NavBar";
import Sidemenu from "../Sidemenu/Sidemenu";
import './profile.css';
import Profileform from "./Profileform"
const Profile = () => {
    const navigateFn = useNavigate();
    const back = () => {
        navigateFn("/conversation")
    }
    return (
        <>
            <NavBar />
            <Row>
                <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={2}
                    xl={2}
                    className="body-margin-top body-margin-bottom-mobile"
                >
                    <Sidemenu />
                </Col>
                <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={22}
                    xl={22}>
                    <Profileform />

                </Col>

            </Row>
            {/* <div>
         
                <Icon icon="icomoon-free:backward" className="white icon-size"  onClick={() => back()}/>
                
            </div> */}

        </>
    )
}
export default Profile;