import { memo, useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useDispatch } from "react-redux";
import { Icon } from "@iconify/react";
import aivolvex from "../../../assets/ui/X Icon SVG.svg";
import { getAvatarDetails } from "../../api/accountAction";
import { useNavigate } from "react-router-dom";

const AvatarList = (props) => {

  return (
    <>
      <div className="">
        <Row gutter={[0, 16]}>
          {props.avatarlist.map((data) => {
            return <AvatarDetails records={data} />;
          })}
        </Row>
      </div>
    </>
  );
};

const AvatarDetails = (props) => {
  const dispatch = useDispatch();
  const navigateFn = useNavigate();
  const [avatardetails, setAvatarDetails] = useState([]);
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );
  useEffect(() => {
    const id = props.records.id;
    const input = {
      id: props.records.id,
      user_id: userName,
    };
    dispatch(getAvatarDetails(input))
      .unwrap()
      .then(({ data }) => {
       
        setAvatarDetails(JSON.parse(data.aivolvex_control_plane)[0]);
      })
      .catch((err) => {
        console.log(err);
        // message.error(err);
      });
  }, [props]);

  return (
    <>
      <Col
        span={24}
        className="cursor-pointer"
        onClick={() => {
          navigateFn(`/conversation/${props.records.id}`);
        }}
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex justify-content-start">
            <div>
              {/* <Icon
          icon="icon-park-outline:add"
          className="volvex-avatar-list-new"
        />{" "} */}
              <img
                className="volvex-sidemenu-list-avatar-logo"
                src={avatardetails.avatar_image}
              />
            </div>
            <div className="volvex-sidemenu-list-avatar-name aivolvex-font">
              {avatardetails.avatar_name}
            </div>
          </div>
          <div className="volvex-sidemenu-list-token-body d-flex justify-content-end">
            {/* <div className="volvex-sidemenu-list-token-value aivolvex-font">
              20
            </div>
            <div className="volvex-sidemenu-list-token-logo">
              <img src={aivolvex} alt="logo" className="token-footer-logo" />
            </div> */}
          </div>
        </div>
      </Col>
    </>
  );
};

export default memo(AvatarList);
