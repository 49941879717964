export const dropDownResult = (selectedlist, selectedvalue) => {
  var checkenvironment;

  return checkenvironment;
};
export const useErrorNavigation = (error) => {
  console.log(error);
  if (error) {
    if (error.networkError && error.networkError.statusCode === 401) {
      // if (error.networkError) {
      // console.log(error);
      localStorage.removeItem("CognitoIdentityServiceProvider.auth");
      localStorage.removeItem("_token");
      localStorage.removeItem("AIVolveXIdentityServiceProvider.com");
      localStorage.removeItem("AIVolveXIdentityServiceProvider.logtype");
      localStorage.removeItem("AIVolveXIdentityServiceProvider.auth");
      window.location.href = "/";
      // window.location.href = "/";
    }
  }
};
// export function simplifyNumber(number) {
//   const billion = 1000000000;
//   const million = 1000000;
//   const thousand = 1000;

//   if (number >= billion) {
//     return (number / billion).toFixed(1) + 'B';
//   } else if (number >= million) {
//     return (number / million).toFixed(1) + 'M';
//   } else if (number >= thousand) {
//     return (number / thousand).toFixed(1) + 'K';
//   } else {
//     return number.toString();
//   }
// }
export function simplifyNumber(number) {
  const billion = 1000000000;
  const million = 1000000;
  const thousand = 1000;

  if (number >= billion) {
    return (number / billion).toFixed(1) + "B";
  } else if (number >= million) {
    return (number / million).toFixed(1) + "M";
  } else if (number >= thousand) {
    return (number / thousand).toFixed(1) + "K";
  } else {
    if (number !== undefined) {
      return number.toString();
    } else {
      return "Null";
    }
  }
}

export function formatDate(inputDate) {
  const options = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const formattedDate = new Date(inputDate).toLocaleString("en-US", options);
  return formattedDate;
}

export function formatFileSize(bytes) {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;
  const gigabyte = megabyte * 1024;

  if (bytes >= gigabyte) {
    return (bytes / gigabyte).toFixed(2) + " GB";
  } else if (bytes >= megabyte) {
    return (bytes / megabyte).toFixed(2) + " MB";
  } else if (bytes >= kilobyte) {
    return (bytes / kilobyte).toFixed(2) + " KB";
  } else {
    return bytes + " Bytes";
  }
}
