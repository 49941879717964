import MarkdownToJSX from "markdown-to-jsx";
import parse from "html-react-parser";
import { Icon } from "@iconify/react";
import { useEffect } from "react";

const ChatPagination = (props) => {
  return (
    <>
      {props.allmessage.map((data, index) => (
        <div
          className={
            props.pagination === +index + +1 ? "page-view" : "page-hide"
          }
        >
          <pre
            className="responce-pre aivolvex-font"
            name={`content-to-convert-${props.indexvalue}`}
          >
            {/* {data.text} */}
            {/* {parse(data.text)} */}
            {props.isMarkdownTableFormat(data) ? (
              <MarkdownToJSX>{data}</MarkdownToJSX>
            ) : (
              <>{parse(data)}</>
            )}

            {data.pdf && (
              <>
                <div className="d-flex justify-content-end">
                  <div>
                    <p>Page : {data.pagenumber} / </p>
                  </div>
                  <div>
                    <p
                      className="bot-kendra-pdf"
                      onClick={() => {
                        window.open(data.pdf, "_blank");
                      }}
                    >
                      <Icon
                        icon="vscode-icons:file-type-pdf2"
                        className="feedback-icon"
                      />{" "}
                      {data.pdfname}{" "}
                    </p>
                  </div>
                </div>
              </>
            )}
          </pre>
        </div>
      ))}
    </>
  );
};

export default ChatPagination;
