import { useState, useEffect } from "react";
import { Col, Row, DatePicker } from "antd";
import dayjs from "dayjs";
import "../CSS/dashboardnew.scss";
import ReactApexChart from "react-apexcharts";
import { gettokenagent, creditdaywise } from "../../api/accountAction";
import { useDispatch, useSelector } from "react-redux";
import { setDashboardDate } from "../../Slice/reducer/chatReducer";
import ModalUsageTrend from "./ModalUsageTrend";

const CreditUsage = () => {
  const { dashbaorddate, offerstatus } = useSelector((state) => state.chat);
  const monthFormat = "YYYY/MM";
  const [labelchart, setLabelChart] = useState([]);
  const [numericvalue, setNumericValue] = useState([]);
  const [labelday, setlabelday] = useState([]);
  const [numbericday, setNumbericday] = useState([]);
  const [threemonthdata, setThreeMonthData] = useState({
    series: [],
    option: [],
  });

  const currentThreeMonthDate = new Date(dashbaorddate);
  const year = currentThreeMonthDate.getFullYear();
  const month = (currentThreeMonthDate.getMonth() + 1)
    .toString()
    .padStart(2, "0");
  const formattedDate = `${year}/${month}`;

  const dispatch = useDispatch();
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );

  const dashboardDate = new Date(dashbaorddate);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName = monthNames[dashboardDate.getMonth()];

  useEffect(() => {
    const avatartoken = {
      event_type: "daywise_avatar_token",
      company: companyName,
      user_id: userName,
      date_time: dashbaorddate,
      user_type: offerstatus,
    };
    dispatch(creditdaywise(avatartoken))
      .unwrap()
      .then(({ data }) => {
        const creditdaywise = JSON.parse(data.aivolvex_control_plane);

        if (creditdaywise) {
          const dates = creditdaywise.map((item) => item.date_time);

          const allDates = Array.from({ length: 31 }, (_, i) =>
            String(i + 1).padStart(2, "0")
          );

          const tokensUsed = Array(31).fill(0);

          allDates.forEach((day, index) => {
            const indexInData = dates.findIndex((date) =>
              date.endsWith(`-${day}`)
            );
            if (indexInData !== -1) {
              tokensUsed[index] = parseInt(
                creditdaywise[indexInData].tokens_used,
                10
              );
            }
          });

          setlabelday(allDates);

          setNumbericday(tokensUsed);
        } else {
          console.log("No data found.");
        }
      })

      .catch((err) => {
        console.log(err);
      });

    const input = {
      event_type: "avatar_token_monthwise",
      company: companyName,
      user_id: userName,
      date_time: dashbaorddate,
      user_type: offerstatus,
    };

    dispatch(gettokenagent(input))
      .unwrap()
      .then(({ data }) => {
        const avatarcredit = JSON.parse(data.aivolvex_control_plane);
        // console.log(avatarcredit)
        //avatar name list
        var avatar_list = avatarcredit.map((item) => item.avatar_name);
        avatar_list = new Set([...avatar_list]);
        avatar_list = [...avatar_list];
        const monthNames = [
          "Jan",
          "Feb",
          "March",
          "April",
          "May",
          "June",
          "July",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        //start :get last 3 month
        const currentThreeMonthDate = new Date(dashbaorddate);
        const lastThreeMonthsDate = new Date(dashbaorddate);
        lastThreeMonthsDate.setMonth(currentThreeMonthDate.getMonth() - 2);
        var month_list = [];
        var chart_records = [];
        var chart_avatar_records = [];
        for (let i = 0; i < 3; i++) {
          let date = new Date(
            lastThreeMonthsDate.getFullYear(),
            lastThreeMonthsDate.getMonth() + i,
            1
          );
          const checkdate = `${date.getFullYear()}-${date.getMonth() + 1}-01`;
          const monthIndex = new Date(checkdate).getMonth();
          const monthName = monthNames[monthIndex];
          avatar_list.map((avatarname) => {
            const monthtokenrecords = avatarcredit.filter((datas) => {
              const inputDate = datas.month;
              const inputDatemonth = inputDate.split("-")[1];
              return (
                datas.avatar_name?.toLowerCase() ===
                  avatarname?.toLowerCase() && inputDatemonth == monthIndex + 1
              );
            });
            if (monthtokenrecords.length > 0) {
              chart_records.push({
                month: checkdate,
                name: avatarname,
                data: monthtokenrecords[0].total_token_used,
              });
            } else {
              chart_records.push({
                month: checkdate,
                name: avatarname,
                data: 0,
              });
            }
          });
          month_list.push(monthName);
        }
        const chart_avatar_values = [];
        avatar_list.map((avatarname) => {
          const last3month = [];
          for (let i = 0; i < 3; i++) {
            let date = new Date(
              lastThreeMonthsDate.getFullYear(),
              lastThreeMonthsDate.getMonth() + i,
              1
            );
            const checkdate = `${date.getFullYear()}-${date.getMonth() + 1}-01`;
            const monthIndex = new Date(checkdate).getMonth();

            const monthtokenrecords = chart_records.filter((datas) => {
              const inputDate = datas.month;
              const inputDatemonth = inputDate.split("-")[1];
              return (
                datas.name?.toLowerCase() === avatarname?.toLowerCase() &&
                inputDatemonth == monthIndex + 1
              );
            });
            if (monthtokenrecords.length > 0) {
              last3month.push(monthtokenrecords[0].data);
            } else {
              last3month.push(0);
            }
          }
          chart_avatar_values.push({
            name: avatarname,
            data: last3month,
          });
        });
        setThreeMonthData({
          series: chart_avatar_values,
          option: month_list,
        });
        //end :get last 3 month

        const currentDateValue = new Date(dashbaorddate);
        const currentYear = currentDateValue.getFullYear();
        const currentMonth = (currentDateValue.getMonth() + 1)
          .toString()
          .padStart(2, "0");

        const currentMonthData = avatarcredit.filter((item) => {
          const [year, month] = item.month.split("-");
          return year === String(currentYear) && month === currentMonth;
        });

        if (currentMonthData.length > 0) {
          const label_chart = currentMonthData.map((item) => item.avatar_name);
          setLabelChart(label_chart);

          const chartdata = currentMonthData.map(
            (item) => item.total_token_used
          );
          const numericValues_db = chartdata.map((value) =>
            parseInt(value, 10)
          );
          setNumericValue(numericValues_db);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dashbaorddate, offerstatus]);

  const series_db = numericvalue;
  const chartData_db = {
    labels: labelchart,
    series: series_db,
  };

  const options_db = {
    chart: {
      type: "pie",
    },
    labels: labelchart,
    dataLabels: {
      formatter: function (val) {
        return val.toFixed(0) + "%";
      },
    },
    legend: {
      position: "right",
      horizontalAlign: "center",
      show: true,
      position: "right",
      labels: {
        colors: "#FFFFFF",
      },
      markers: {
        width: 13,
        height: 13,
        radius: 4,
        strokeWidth: 0,
      },
    },
    tooltip: {
      enabled: true,
      theme: "light",
      y: {
        formatter: function (val) {
          // const billion = 1000000000;
          // const million = 1000000;
          // const thousand = 1000;

          // if (val >= billion) {
          //   return (val / billion).toFixed(1) + 'B';
          // } else if (val >= million) {
          //   return (val / million).toFixed(1) + 'M';
          // } else if (val >= thousand) {
          //   return (val / thousand).toFixed(1) + 'K';
          // } else {
          //   return val.toString();
          // }
          return val;
        },
      },
    },
    colors: ["#9FDEF1", "#2A5D78", "#A7EE7C", "#FFFFFF", "#6197E2"],
    stroke: {
      show: false,
    },
    title: {
      text: `${monthName} Month Credits`,
      align: "left",
      style: {
        color: "#FFFFFF",
        fontWeight: "lighter",
      },
    },
  };

  // console.log(options_db.legend)
  const seriesData = threemonthdata.series;

  const chartOptions = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      stackType: "100%",
    },
    grid: {
      show: true,
      borderColor: "#42445f",
    },
    plotOptions: {
      bar: {
        columnWidth: "40%",
      },
    },
    legend: {
      show: false,
    },
    title: {
      text: "Last Three Months Credits",
      align: "left",
      style: {
        color: "#FFFFFF",
        fontWeight: "lighter",
      },
    },
    xaxis: {
      categories: threemonthdata.option,
      labels: {
        style: {
          colors: "#fff",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#42445f",
        },
      },
      title: {
        text: "Credits",
        style: {
          color: "#fff",
        },
      },
    },
    fill: {
      colors: [
        "#A7EE7C",
        "#FFB4D8",
        "#FF9B9B",
        "#FFFFFF",
        "#65CCD2",
        "#FDF7C3",
        "#FFB4D8",
      ],
    },
    options: {
      legend: {
        position: "bottom",
        markers: {
          size: 12,
          strokeWidth: 0,
        },
        labels: {
          colors: "#FFFFFF",
        },
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: false,
      },
      y: {
        formatter: function (val) {
          const billion = 1000000000;
          const million = 1000000;
          const thousand = 1000;

          if (val >= billion) {
            return (val / billion).toFixed(1) + "B";
          } else if (val >= million) {
            return (val / million).toFixed(1) + "M";
          } else if (val >= thousand) {
            return (val / thousand).toFixed(1) + "K";
          } else {
            return val.toString();
          }
          return val;
        },
      },
    },

    dataLabels: {
      enabled: false,
    },
  };

  const lineseries = [
    {
      name: "Credit",
      data: numbericday,
      color: "#EA5F89",
    },
  ];

  const lineoptions = {
    chart: {
      height: 350,
      type: "area",
      zoom: {
        enabled: false,
      },
    },
    grid: {
      show: true,
      borderColor: "#42445f",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 0,
    },
    color: ["#fff"],
    title: {
      text: "Credits By Day",
      align: "left",
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: "Poppins",
        color: "#fff",
      },
    },
    xaxis: {
      categories: labelday,
      labels: {
        rotate: -45,
        rotateAlways: true,
        style: {
          fontSize: "10px",
          colors: "#ffffff",
        },
        formatter: (num) => {
          const monthNamesShort = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          const date = new Date(dashbaorddate);
          const day = date.getDate();
          const month = monthNamesShort[date.getMonth()];
          const current_month_text = `${num}/${month}`;
          return current_month_text;
        },
      },
      title: {
        text: "Credits Count",
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          fontFamily: "Poppins",
          color: "#fff",
        },
      },
      tooltip: {
        enabled: true,
        x: {
          show: false, // If you don't want to show the x-value
        },
        y: {
          formatter: function (val) {
            const billion = 1000000000;
            const million = 1000000;
            const thousand = 1000;

            if (val >= billion) {
              return (val / billion).toFixed(1) + "B";
            } else if (val >= million) {
              return (val / million).toFixed(1) + "M";
            } else if (val >= thousand) {
              return (val / thousand).toFixed(1) + "K";
            } else {
              return val.toString();
            }
            return val;
          },
        },
      },
    },

    yaxis: {
      labels: {
        style: {
          colors: "#42445f",
        },
        formatter: (value) => {
          const num = (Math.round(value * 100) / 100).toFixed(2);
          return Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
            : Math.sign(num) * Math.abs(num);
        },
      },
    },
  };

  const handleMonthChange = (value) => {
    const dateFormat = "YYYY-MM-DD";
    dispatch(setDashboardDate(value.format(dateFormat)));
  };
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };
  return (
    <>
      <Row className="mt-4">
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          className="d-flex justify-content-between"
        >
          <div className="d-flex justify-content-between align-items-center full-width">
            <div>
              <h4 className="white-color mt-4 aivolvex-font">Dashboard</h4>
            </div>
            <div>
              <DatePicker
                className="dashboard-datepicker"
                defaultValue={dayjs(formattedDate, monthFormat)}
                format={monthFormat}
                picker="month"
                onChange={handleMonthChange}
                disabledDate={disabledDate}
              />
            </div>
          </div>
        </Col>

        <Col span={24}>
          <ModalUsageTrend />
        </Col>
        <br />
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          className="d-flex justify-content-center mt-4"
        >
          <h4 className="dashboard-credit aivolvex-font">
            Credits Usage Trend
          </h4>
        </Col>
      </Row>
      {/* <Row span={24}>
        <Col span={24}>
          <ModalUsageTrend />
        </Col>
      </Row> */}
      <Row className="mt-3 d-flex justify-content-between" gutter={[16, 16]}>
        <Col span={12}>
          <div className="aivolvex-dashboard-outline full-width">
            <div className="Dashboard_graph_box Dashboard_head_box_shadow">
              <div
                className="Dashboard_head_content"
                style={{ position: "relative" }}
              >
                <div id="chart">
                  <ReactApexChart
                    // title="Current Month Token"
                    options={options_db}
                    series={chartData_db.series}
                    type="pie"
                    height={234}
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>

        <Col span={12}>
          <div className="aivolvex-dashboard-outline full-width">
            <div className="Dashboard_graph_box Dashboard_head_box_shadow">
              <div className="Dashboard_head_content">
                <div id="chart">
                  <>
                    <ReactApexChart
                      options={chartOptions}
                      series={seriesData}
                      type="bar"
                      height={220}
                    />
                  </>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row
        className="mt-3 d-flex justify-content-between"
        gutter={[16, 16, 16, 16]}
      >
        <div className="aivolvex-dashboard-outline full-width">
          <div className="Dashboard_graph_box Dashboard_head_box_shadow">
            <div className="Dashboard_head_content">
              <div id="chart">
                <ReactApexChart
                  options={lineoptions}
                  series={lineseries}
                  type="area"
                  height={234}
                />
              </div>
            </div>
          </div>
        </div>
      </Row>
    </>
  );
};

export default CreditUsage;
