import { memo, useState } from "react";
import { AutoComplete, Form } from "antd";

const InputAutoComplete = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const [tagavatarlist, setTagAvatarList] = useState(
    props.avatarlist.map((data) => data.avatar_name)
  );

  const onFocusInput = () => {
    setDataSource(tagavatarlist);
  };

  const onNameChange = (value) => {
    const filteredNames = mockFetchNames(value);
    setDataSource(filteredNames);
  };

  const mockFetchNames = (value) => {
    const allNames = tagavatarlist;
    return allNames.filter((name) =>
      name.toLowerCase().includes(value.toLowerCase())
    );
  };

  return (
    <>
      <Form.Item
        {...props.restField}
        name={[props.field.name, "fieldName"]}
        className="mar-0"
      >
        <AutoComplete
          style={{ width: 200 }}
          options={dataSource.map((name) => ({
            value: name,
          }))}
          onSearch={onNameChange}
          onFocus={onFocusInput} 
          placeholder="Type here"
          className="aivolvex-tag-input"
        />
      </Form.Item>
    </>
  );
};

export default memo(InputAutoComplete);
