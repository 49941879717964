import { Input } from "antd";
import { memo, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import aivolvex from "../../../assets/ui/X Icon SVG.svg";

const SharedHistory = (props) => {
  const contentRef = useRef(null);
  const tag_id = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.tags")
  );
  const [searchQuery, setSearchQuery] = useState("");
  const filteredPrompts = props.promptslist?.filter((data) =>
    data.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <>
      <div className="aivolvex-font">
        <div className="history-search">
          <Input
            placeholder="Search here"
            prefix={
              <Icon
                icon="material-symbols:search"
                className="lib-search-icon"
              />
            }
            className="lib-history"
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
            bordered={false}
          />
        </div>

        <div className="side-scroll-container-prompts">
          <button
            class="button-primary-questions  btn btn-primary fontSize10"
            role="button"
          >
            <div className="btn-main-box d-flex justify-content-between">
              <div className="btn-prompts-content">
                <Icon
                  icon="solar:share-outline"
                  className="about-header-icon margin-right-0-5"
                />
                Provide the spending for each environments in august
              </div>
            </div>
          </button>

          {/* {filteredPrompts.map((data) => {
            return (
              <button
                class="button-primary-questions  btn btn-primary fontSize10"
                role="button"
                onClick={() => {
                  props.getPromptsContent(data);
                }}
              >
                <div className="btn-main-box d-flex justify-content-between">
                  <div className="btn-prompts-content">{data}</div>
                  {tag_id === "all" && (
                    <div className="btn-prompts-token">
                      <span className="d-flex justify-content-between">
                        <span>100</span>{" "}
                        <img
                          src={aivolvex}
                          alt="logo"
                          className="token-footer-logo"
                        />
                      </span>
                    </div>
                  )}
                </div>
              </button>
            );
          })} */}
        </div>
      </div>
    </>
  );
};

export default memo(SharedHistory);
