import React, { useState } from "react";
import { Icon } from "@iconify/react";

const ChatCopy = (props) => {
  const [copyconf, setCopyConf] = useState(false);
  const handleCopyClick = (conversation) => {
    const plainText = extractPlainText(conversation);
    const textArea = document.createElement("textarea");
    textArea.value = plainText;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      setCopyConf(true);
      setTimeout(() => {
        setCopyConf(false);
      }, 2000);
      document.execCommand("copy");
    } catch (error) {
      console.error("Copy failed:", error);
    }

    document.body.removeChild(textArea);
  };

  //copy
  const extractPlainText = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || "";
  };

  return (
    <>
      {copyconf ? (
        <Icon icon="charm:tick" className="feedback-icon" />
      ) : (
        <Icon
          icon="ic:round-file-copy"
          className="feedback-icon"
          onClick={() => handleCopyClick(props.text)}
        />
      )}
    </>
  );
};

export default ChatCopy;
